import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './header.module.css'
import img_logo from '../../static/images/logo.svg';
import img_avatar from './static/images/avatar.svg';
import ProfileMenu from './profile-menu';


const Header = (props) => {
    const navigate = useNavigate();
    const [is_show_profile_menu, show_profile_menu] = useState(false);

    const on_analytics_click = (event) => {
        navigate('/account/analytics');
    }

    const on_my_trades_click = (event) => {
        navigate('/account/mytrades');
    }

    const on_diary_click = (event) => {
        navigate('/account/diary');
    }

    const on_api_keys_click = (event) => {
        navigate('/account/apikeys');
    }

    const on_profile_click = (event) => {
        navigate('/account/profile');
    }

    const on_profile_menu_click = (event) => {
        event.stopPropagation();
        show_profile_menu(true);
    };

    const on_logo_click = (event) => {
        navigate('/');
    }

    return (
        <header className={styles.header}>
            <div className={styles.header_content}>
                <div className={styles.logo} onClick={(event) => on_logo_click(event)}>
                    <img className={styles.logo__icon} src={img_logo} alt="Logo"/>
                    <p className={styles.logo__text}>Trader<br/>diary</p>
                </div>
                <nav className={styles.navigation}>
                    <ul className={styles.navigation_list}>
                        <li className={styles.navigation__item + ' ' + styles.navigation__link}
                            onClick={(event) => on_analytics_click(event)}>
                            Аналитика
                        </li>
                        <li className={styles.navigation__item + ' ' + styles.navigation__link}
                            onClick={(event) => on_my_trades_click(event)}>
                            Мои трейды
                        </li>
                        <li className={styles.navigation__item + ' ' + styles.navigation__link}
                            onClick={(event) => on_diary_click(event)}>
                            Дневник
                        </li>
                        <li className={styles.navigation__item + ' ' + styles.navigation__link}
                            onClick={(event) => on_api_keys_click(event)}>
                            API ключи
                        </li>
                        <li className={styles.navigation__item + ' ' + styles.navigation__link}
                            onClick={(event) => on_profile_click(event)}>
                            Профиль
                        </li>
                    </ul>
                </nav>
                <div className={styles.profile_menu} onClick={(event) => {
                    on_profile_menu_click(event)
                }}>
                    {is_show_profile_menu ? <ProfileMenu user_info={props.user_info} show_profile_menu={show_profile_menu} /> : <template/> }
                    <img className={styles.profile_menu__avatar} src={img_avatar} alt="Avatar"/>
                    <p className={styles.profile_menu__user_name}>
                        {props.user_info && props.user_info['user_name'] ? props.user_info.user_name : null}
                    </p>
                </div>
            </div>
        </header>
    );
};

export default Header;
