import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {decimal_to_string} from '../../../../static/js/numutils';
import {get_request_positions_data} from './controller';
import {add_handler, remove_handler} from '../../../../static/js/handlers';
import styles from './mytrades.module.css';


const OrdersData = (props) => {
    const [rows_data_uuid, set_rows_data_uuid] = useState(uuid());
    const rows_data = useRef(null);

    useEffect(() => {
        add_handler('my_trades', 'on_positions_updated', on_positions_updated);
        on_positions_updated(null);
        return () => {
            remove_handler('my_trades', 'on_positions_updated', on_positions_updated);
        };
    }, []);

    const on_positions_updated = (p) => {
        if (!get_request_positions_data() || (props.index >= get_request_positions_data().positions.length))
            return;
        rows_data.current = create_orders(get_request_positions_data().positions[props.index].orders);
        set_rows_data_uuid(uuid());
    };

    const side_to_hrt = (name) => {
        if (name === null)
            return '-';
        switch(name) {
            case 'buy':
                return 'BUY';
            case 'sell':
                return 'SELL';
        }
        return name;
    };

    const type_to_class = (name) => {
        if (name === null)
            return styles.order_row__marker_limit;
        switch(name) {
            case 'funding':
                return styles.order_row__marker_limit;
            case 'market':
                return styles.order_row__marker_market;
            case 'limit':
                return styles.order_row__marker_limit;
        }
        return styles.order_row__marker_limit;
    };

    const create_orders = (orders) => {
        let rows = [];
        let row_index = 0;
        for(const order of orders) {
            ++row_index;
            if (order.type === 'funding') {
                rows.push([
                    <div key={`order-row-${row_index}-1`} className={styles.order_row__background}
                         style={{gridArea: `${row_index} / 1 / auto / 7`}}/>,
                    <div key={`order-row-${row_index}-2`} className={styles.order_row__column} style={{gridArea: `${row_index} / 1`}}>
                        <p key={`order-row-${row_index}-3`} className={styles.order_row__header_text}>Сторона</p>
                        <p key={`order-row-${row_index}-4`} className={styles.order_row__value_text}>
                            Фандинг
                            <span key={`order-row-${row_index}-5`} className={styles.order_row__marker + ' ' + type_to_class(order.type)}/>
                        </p>
                    </div>,
                    <div key={`order-row-${row_index}-6`} className={styles.order_row__column} style={{gridArea: `${row_index} / 2`}}>
                        <p key={`order-row-${row_index}-7`} className={styles.order_row__header_text}></p>
                        <p key={`order-row-${row_index}-8`} className={styles.order_row__value_text}>
                            -
                        </p>
                    </div>,
                    <div key={`order-row-${row_index}-9`} className={styles.order_row__column} style={{gridArea: `${row_index} / 3`}}>
                        <p key={`order-row-${row_index}-10`} className={styles.order_row__header_text}></p>
                        <p key={`order-row-${row_index}-11`} className={styles.order_row__value_text}>
                            -
                        </p>
                    </div>,
                    <div key={`order-row-${row_index}-12`} className={styles.order_row__column} style={{gridArea: `${row_index} / 4`}}>
                        <p key={`order-row-${row_index}-13`} className={styles.order_row__header_text}></p>
                        <p key={`order-row-${row_index}-14`} className={styles.order_row__value_text}>
                            -
                        </p>
                    </div>,
                    <div key={`order-row-${row_index}-15`} className={styles.order_row__column} style={{gridArea: `${row_index} / 5`}}>
                        <p key={`order-row-${row_index}-16`} className={styles.order_row__header_text}></p>
                        <p key={`order-row-${row_index}-17`} className={styles.order_row__value_text}>
                            -
                        </p>
                    </div>,
                    <div key={`order-row-${row_index}-18`} className={styles.order_row__column} style={{gridArea: `${row_index} / 6`}}>
                        <p key={`order-row-${row_index}-19`} className={styles.order_row__header_text}>Комиссия ($)</p>
                        <p key={`order-row-${row_index}-20`} className={styles.order_row__value_text}>
                            {decimal_to_string(order.fee_value, 2, '$')}
                        </p>
                    </div>,
                ]);
            } else {
                rows.push([
                    <div key={`order-row-${row_index}-1`} className={styles.order_row__background}
                         style={{gridArea: `${row_index} / 1 / auto / 7`}}/>,
                    <div key={`order-row-${row_index}-2`} className={styles.order_row__column} style={{gridArea: `${row_index} / 1`}}>
                        <p key={`order-row-${row_index}-3`} className={styles.order_row__header_text}>Сторона</p>
                        <p key={`order-row-${row_index}-4`} className={styles.order_row__value_text}>
                            {side_to_hrt(order.side)}
                            <span key={`order-row-${row_index}-5`} className={styles.order_row__marker + ' ' + type_to_class(order.type)}/>
                        </p>
                    </div>,
                    <div key={`order-row-${row_index}-6`} className={styles.order_row__column} style={{gridArea: `${row_index} / 2`}}>
                        <p key={`order-row-${row_index}-7`} className={styles.order_row__header_text}>Всего оборот</p>
                        <p key={`order-row-${row_index}-8`} className={styles.order_row__value_text}>
                            {decimal_to_string(order.qty, 8)}
                        </p>
                    </div>,
                    <div key={`order-row-${row_index}-9`} className={styles.order_row__column} style={{gridArea: `${row_index} / 3`}}>
                        <p key={`order-row-${row_index}-10`} className={styles.order_row__header_text}>Цена</p>
                        <p key={`order-row-${row_index}-11`} className={styles.order_row__value_text}>
                            {decimal_to_string(order.price, 8, '$')}
                        </p>
                    </div>,
                    <div key={`order-row-${row_index}-12`} className={styles.order_row__column} style={{gridArea: `${row_index} / 4`}}>
                        <p key={`order-row-${row_index}-13`} className={styles.order_row__header_text}>Объем ($)</p>
                        <p key={`order-row-${row_index}-14`} className={styles.order_row__value_text}>
                            {decimal_to_string(order.volume_value, 2, '$')}
                        </p>
                    </div>,
                    <div key={`order-row-${row_index}-15`} className={styles.order_row__column} style={{gridArea: `${row_index} / 5`}}>
                        <p key={`order-row-${row_index}-16`}  className={styles.order_row__header_text}>PNL ($)</p>
                        <p key={`order-row-${row_index}-17`} className={styles.order_row__value_text}>
                            {decimal_to_string(order.pnl_value, 2, '$')}
                        </p>
                    </div>,
                    <div key={`order-row-${row_index}-18`} className={styles.order_row__column} style={{gridArea: `${row_index} / 6`}}>
                        <p key={`order-row-${row_index}-19`} className={styles.order_row__header_text}>Комиссия ($)</p>
                        <p key={`order-row-${row_index}-20`} className={styles.order_row__value_text}>
                            {decimal_to_string(order.fee_value, 2, '$')}
                        </p>
                    </div>,
                ]);
            }
        }
        return rows;
    };

    return (
        <div key={rows_data_uuid} className={styles.orders_table}>
            {rows_data.current}
        </div>
    );
};

export default OrdersData;
