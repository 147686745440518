import {is_dev_env} from './sysenv';
import {get_csrf_token_by_url} from './csrftoken';


let global_csrf_token = null;

export function get_base_url() {
    if (is_dev_env())
        return `http://${window.location.hostname}:8000`
    else
        return `https://${window.location.host}`;
}

export function get_csrf_token() {
    if (!global_csrf_token)
        get_csrf_token_by_url('/api/auth/getcsrftoken/', (csrf_token) => {global_csrf_token = csrf_token});
    return global_csrf_token;
}

export function get_headers(content_type) {
    return {
        'Content-Type': content_type,
        'X-CSRFToken': get_csrf_token(),
    };
}

export function get_headers_by_type(type) {
    switch(type) {
        case 'form':
            return get_headers('application/x-www-form-urlencoded');
        case 'json':
            return get_headers('application/json');
        case 'plain':
            return get_headers('text/plain');
        case 'html':
            return get_headers('text/html');
        default:
            break;
    }
    return {};
}

export function get_axios_config(method, type = 'json') {
    switch(method) {
        case 'get':
        case 'post':
            return {
                withCredentials: true,
                baseURL: get_base_url(),
                headers: get_headers_by_type(type)
            };
        default:
            break;
    }
    return {};
}
