import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import styles from './diary-week.module.css';
import weeks_styles from './diary-weeks.module.css';
import img_arrow_left from "../../../../static/images/arrow-1-left-white.svg";


const DiaryWeek = (props) => {
    const navigate = useNavigate();

    const on_back_button_click = (event) => {
        navigate('/account/diary/weeks');
    };

    const on_analyse_day_button_click = (event) => {
        navigate('/account/diary/day');
    };

    const create_days = () => {
        let days = [];
        for(let i = 0; i < 7; ++i) {
            days.push(
                <div key={uuid()} className={styles.day}>
                    <div key={uuid()} className={styles.day_header}>
                        <p key={uuid()} className={styles.day_header__text}>
                            Понедельник, 29 Июля
                        </p>
                    </div>
                    <div key={uuid()} className={styles.day_content}>
                        <div key={uuid()} className={styles.day_stat}>
                            <p className={styles.day_stat__value}>
                                50%
                            </p>
                            <p className={styles.day_stat__name}>
                                Win rate<br/>
                                Win 4/ 8
                            </p>
                        </div>
                        <div key={uuid()} className={styles.day_stat}>
                            <p className={styles.day_stat__value+' '+styles.stat__positive}>
                                $10.13
                            </p>
                            <p className={styles.day_stat__name}>
                                Net profit<br/>
                                Volume $12,928.68
                            </p>
                        </div>
                        <div key={uuid()} className={styles.day_stat}>
                            <p className={styles.day_stat__value}>
                                2.22
                            </p>
                            <p className={styles.day_stat__name}>
                                Payoff ratio<br/>
                                $4.62 / $2.08
                            </p>
                        </div>
                        <div key={uuid()} className={styles.day_stat}>
                            <p className={styles.day_stat__value}>
                                37.5%
                            </p>
                            <p className={styles.day_stat__name}>
                                Long trades (3)<br/>
                                Short 62.5% (5)
                            </p>
                        </div>
                        <div key={uuid()} className={styles.day_stat}>
                            <p className={styles.day_stat__value+' '+styles.stat__negative}>
                                -12$.8
                            </p>
                            <p className={styles.day_stat__name}>
                                Commission<br/>
                                Average $1.60
                            </p>
                        </div>
                    </div>
                    <div key={uuid()} className={styles.day_footer}>
                        <button id={uuid()} className={styles.analyse_day_button}
                                onClick={(event) => on_analyse_day_button_click(event)}>
                            Анализировать
                        </button>
                    </div>
                </div>
            );
        }
        return days;
    };

    return (
        <div className={styles.block_week}>
            <div className={weeks_styles.navigator}>
                <button id={uuid()} className={weeks_styles.back_button}
                        onClick={(event) => on_back_button_click(event)}>
                    <img className={weeks_styles.back_button__img} src={img_arrow_left} alt='Back'/>
                </button>
                <p className={weeks_styles.weeks__title}>Январь 2024</p>
            </div>
            <div className={styles.week}>
                <div className={styles.scrollbar_top_shadow}/>
                <div className={styles.week__scrollbar}>
                    <div className={weeks_styles.week}>
                        <p className={weeks_styles.week__title}>Неделя - {3}</p>
                        <div className={weeks_styles.header}>
                            <p className={weeks_styles.week_summary__text}>
                                Период
                                <span className={weeks_styles.week_summary__date_text}>
                                        29 Июля 2024 - 4 Август 2024
                                    </span>
                            </p>
                        </div>
                        <div className={weeks_styles.content}>
                            <div className={weeks_styles.stats}>
                                <div className={weeks_styles.stat}>
                                    <p className={weeks_styles.stat__value}>
                                        50%
                                    </p>
                                    <p className={weeks_styles.stat__name}>
                                        Win rate<br/>
                                        Win 4/ 8
                                    </p>
                                </div>
                                <div className={weeks_styles.stat}>
                                    <p className={weeks_styles.stat__value + ' ' + weeks_styles.stat__positive}>
                                        $10.13
                                    </p>
                                    <p className={weeks_styles.stat__name}>
                                        Net profit<br/>
                                        Volume $12,928.68
                                    </p>
                                </div>
                                <div className={weeks_styles.stat}>
                                    <p className={weeks_styles.stat__value}>
                                        2.22
                                    </p>
                                    <p className={weeks_styles.stat__name}>
                                        Payoff ratio<br/>
                                        $4.62 / $2.08
                                    </p>
                                </div>
                                <div className={weeks_styles.stat}>
                                    <p className={weeks_styles.stat__value}>
                                        37.5%
                                    </p>
                                    <p className={weeks_styles.stat__name}>
                                        Long trades (3)<br/>
                                        Short 62.5% (5)
                                    </p>
                                </div>
                                <div className={weeks_styles.stat}>
                                    <p className={weeks_styles.stat__value + ' ' + weeks_styles.stat__negative}>
                                        -12$.8
                                    </p>
                                    <p className={weeks_styles.stat__name}>
                                        Commission<br/>
                                        Average $1.60
                                    </p>
                                </div>
                            </div>
                            <div className={weeks_styles.week_comments}>
                                <textarea key={uuid()} id={uuid()} className={weeks_styles.comments_textarea}></textarea>
                            </div>
                        </div>
                    </div>
                    <hr className={styles.hor_separator}/>
                    <div className={styles.days}>
                        {create_days()}
                    </div>
                </div>
                <div className={styles.scrollbar_bottom_shadow}/>
            </div>
        </div>
    );
};

export default DiaryWeek;
