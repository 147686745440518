import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import styles from './diary-months.module.css';
import img_arrow_down from "../../../../static/images/arrow-1-down-white.svg";


const DiaryMonths = (props) => {
    const navigate = useNavigate();
    const [current_year, set_current_year] = useState((new Date()).getFullYear());

    useEffect(() => {
        window.addEventListener('click', on_window_click);
        return () => {
            window.removeEventListener('click', on_window_click);
        };
    }, []);

    const on_window_click = (event) => {
        if (!(event.target.closest(`[class*=${styles.dropdown}]`)))
            on_close_year_dropdown();
    };

    const on_year_dropdown_click = (event) => {
        const dropdown = event.target.closest(`[class*="${styles.dropdown}"]`);
        if (!dropdown)
            return;
        event.stopPropagation();
        const button = dropdown.querySelector(`[class*="${styles.dropdown_button}"]`);
        if (button) {
            if (button.classList.contains(styles.dropdown_button__checked))
                return on_close_year_dropdown();
            else
                button.classList.add(styles.dropdown_button__checked);
        }
        const dropdown_content = dropdown.querySelector(`[class*="${styles.dropdown_content}"]`);
        if (dropdown_content) {
            if (dropdown_content.classList.contains(styles.dropdown_content__visible)) {
                return on_close_year_dropdown();
            } else {
                dropdown_content.classList.add(styles.dropdown_content__visible);
                const items = dropdown_content.querySelectorAll(`[class*="${styles.dropdown_content__item}"]`);
                for(const item of items) {
                    item.classList.remove(styles.dropdown_content__item__selected);
                    if (Number(item.textContent) === current_year) {
                        item.classList.add(styles.dropdown_content__item__selected);
                    }
                }
            }
        }
    };

    const on_close_year_dropdown = () => {
        const button = document.querySelector(`[class*="${styles.dropdown_button}"]`);
        if (button)
            button.classList.remove(styles.dropdown_button__checked);
        const dropdown_content = document.querySelector(`[class*="${styles.dropdown_content}"]`);
        if (dropdown_content)
            dropdown_content.classList.remove(styles.dropdown_content__visible);
    }

    const on_year_dropdown_item_click = (event) => {
        const item = event.target.closest(`[class*="${styles.dropdown_content__item}"]`);
        if (!item)
            return;
        event.stopPropagation();
        set_current_year(Number(event.target.textContent));
        on_close_year_dropdown();
    }

    const on_analyse_month_click = (event) => {
        console.log(props);
        navigate('/account/diary/weeks');
    }

    const create_months = () => {
        let months = [];
        for(let i = 0; i < 5; ++i) {
            months.push(
                <div key={uuid()} className={styles.month}>
                    <p key={uuid()} className={styles.month__title}>Месяц</p>
                    <div key={uuid()} className={styles.section_1}>
                        <p key={uuid()} className={styles.month_name}>Сентябрь</p>
                        <button key={uuid()} id={uuid()} className={styles.analyse_month_button}
                                onClick={(event) => on_analyse_month_click(event)}>
                            Анализировать
                        </button>
                    </div>
                    <textarea key={uuid()} id={uuid()} className={styles.comments_textarea}></textarea>
                    <div key={uuid()} className={styles.stats}>
                        <div key={uuid()} className={styles.stat}>
                            <p key={uuid()} className={styles.stat__value + ' ' + styles.stat__positive}>+$45.35</p>
                            <p key={uuid()} className={styles.stat__name}>Net profit</p>
                        </div>
                        <div key={uuid()} className={styles.stat_separator}/>
                        <div key={uuid()} className={styles.stat}>
                            <p key={uuid()} className={styles.stat__value}>37</p>
                            <p key={uuid()} className={styles.stat__name}>Trades</p>
                        </div>
                        <div key={uuid()} className={styles.stat_separator}/>
                        <div key={uuid()} className={styles.stat}>
                            <p key={uuid()} className={styles.stat__value}>+$12928.68</p>
                            <p key={uuid()} className={styles.stat__name}>Volume</p>
                        </div>
                        <div key={uuid()} className={styles.stat_separator}/>
                        <div key={uuid()} className={styles.stat}>
                            <p key={uuid()} className={styles.stat__value + ' ' + styles.stat__negative}>-50%</p>
                            <p key={uuid()} className={styles.stat__name}>Win Rate</p>
                        </div>
                    </div>
                </div>
            );
        }
        return months;
    };

    return (
        <div className={styles.block_months}>
            <div className={styles.year}>
                <div className={styles.dropdown}>
                    <button className={styles.dropdown_button}
                            onClick={(event) => on_year_dropdown_click(event)}>
                        {current_year}
                        <img className={styles.dropdown_button__arrow_down} src={img_arrow_down} alt='Open'/>
                    </button>
                    <div className={styles.dropdown_content}>
                        <ul className={styles.dropdown_content__list}>
                            <li className={styles.dropdown_content__item}
                                onClick={(event) => on_year_dropdown_item_click(event)}>
                                2024
                            </li>
                            <li className={styles.dropdown_content__item}
                                onClick={(event) => on_year_dropdown_item_click(event)}>
                                2023
                            </li>
                            <li className={styles.dropdown_content__item}
                                onClick={(event) => on_year_dropdown_item_click(event)}>
                                2022
                            </li>
                            <li className={styles.dropdown_content__item}
                                onClick={(event) => on_year_dropdown_item_click(event)}>
                                2021
                            </li>
                            <li className={styles.dropdown_content__item}
                                onClick={(event) => on_year_dropdown_item_click(event)}>
                                2020
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={styles.months}>
                <div className={styles.scrollbar_top_shadow}/>
                <div className={styles.months_scrollbar}>
                    {create_months()}
                </div>
                <div className={styles.scrollbar_bottom_shadow}/>
            </div>
        </div>
    );
};

export default DiaryMonths;
