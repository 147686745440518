import axios from "axios";
import {get_base_url} from "./network";


export function get_csrf_token_by_url(url, set_csrf_token)  {
    axios.get(url, { baseURL: get_base_url(), withCredentials: true })
        .then((response) => {
            if (response.status === 200)
                return set_csrf_token(response.headers.get('X-CSRFToken'));
        })
        .catch((error) => {
            console.error(error);
        });
}
