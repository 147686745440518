import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import styles from './diary.module.css';
import DiaryMonths from "./diary-months";
import DiaryWeeks from "./diary-weeks";
import DiaryWeek from "./diary-week";
import DiaryDay from "./diary-day";
import img_diary from './static/images/diary.svg';


const Diary = () => {
    const navigate = useNavigate();
    const [diary_props, set_diary_props] = useState({
    });

    return (
        <div className={styles.block}>
            <div className={styles.diary_header}>
                <img className={styles.header__diary_img} src={img_diary} alt='Diary'/>
                <p className={styles.header__title}>Дневник</p>
                <p className={styles.header__text}>
                    Анализируйте свои торговые<br/>месяцы, недели и дни
                </p>
            </div>
            <div className={styles.diary_content}>
                <Routes>
                    <Route path='/' element={<Navigate to={'/account/diary/months'} />}/>
                    <Route path='/months' element={<DiaryMonths {...diary_props} />}/>
                    <Route path='/weeks' element={<DiaryWeeks {...diary_props} />}/>
                    <Route path='/week' element={<DiaryWeek {...diary_props} />}/>
                    <Route path='/day' element={<DiaryDay {...diary_props} />}/>
                    <Route path='*' element={<Navigate to={'/account'} />}/>
                </Routes>
            </div>
        </div>
    );
};

export default Diary;
