import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import styles from './textarea.module.css';
import axios from 'axios';
import {get_axios_config} from '../../static/js/network';
import DOMPurify from 'dompurify';
import {get_request_positions_data} from '../account/components/mytrades/controller';
import {type} from "@testing-library/user-event/dist/type";


const TextArea = (props) => {
    const [textarea_uuid] = useState(() => uuid());
    const current_text = useRef('');
    const commit_text = useRef('');
    const commited_text = useRef('');

    useEffect(() => {
        const interval_id = setInterval(on_write, 5000);
        if (props['text'] === undefined) {
            on_load();
        } else {
            set_text(props['text'])
        }
        return () => {
            clearInterval(interval_id);
            on_write();
        };
    }, []);

    const set_text = (text) => {
        if (typeof text !== 'string')
            return;
        const textarea = document.querySelector(`#textarea-${textarea_uuid}`);
        if (textarea) {
            textarea.value = DOMPurify.sanitize(text.trim());
            current_text.current = textarea.value;
            commited_text.current = current_text.current;
            if (props['auto_grow'])
                auto_grow(textarea);
        }
    };

    const on_load = () => {
        if (!props['on_load'])
            return;
        props.on_load(props['get_method'], set_text, props['kwargs'] ? props['kwargs'] : {});
    };

    const on_write = () => {
        if (!props['on_write'])
            return;
        props.on_write(props['write_method'], current_text, commit_text, commited_text, props['kwargs'] ? props['kwargs'] : {});
    };

    const get_min_height = () => {
        if (props['style'] && props['style']['minHeight'])
            return props['style']['minHeight'];
        return '0';
    };

    const auto_grow = (elem) => {
        if (elem.scrollHeight > parseInt(elem.style.maxHeight, 10)) {
            elem.style.height = elem.style.maxHeight;
        } else {
            elem.style.minHeight = get_min_height();
            elem.style.height = '5px';
            elem.style.height = `${elem.scrollHeight}px`;
        }
        elem.style.minHeight = elem.style.height;
    };

    const on_input = (event) => {
        current_text.current = DOMPurify.sanitize(event.target.value.trim());
        if (props['auto_grow'])
            auto_grow(event.target);
    };

    const on_blur = (event) => {
        on_write();
    };

    return (
        <textarea id={`textarea-${textarea_uuid}`}
                  style={props['style'] ? props['style'] : null}
                  className={styles.textarea}
                  placeholder={props['placeholder'] ? props['placeholder'] : null}
                  maxLength={props['maxlength'] ? props['maxlength'] : '4096'}
                  onInput={(event) => on_input(event)}
                  onBlur={(event) => on_blur(event)}/>
    );
};

export default TextArea;
