import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import styles from './diary-weeks.module.css';
import img_arrow_left from '../../../../static/images/arrow-1-left-white.svg';


const DiaryWeeks = (props) => {
    const navigate = useNavigate();

    const on_back_button_click = (event) => {
        navigate('/account/diary/months');
    };

    const on_analyse_week_click = (event) => {
        navigate('/account/diary/week');
    };

    const create_weeks = () => {
        let weeks = [];
        for(let i = 0; i < 4; ++i) {
            weeks.push(
                <div key={uuid()} className={styles.week}>
                    <p key={uuid()} className={styles.week__title}>Неделя - {i + 1}</p>
                    <div key={uuid()} className={styles.header}>
                        <p key={uuid()} className={styles.week_summary__text}>
                            Период
                            <span key={uuid()} className={styles.week_summary__date_text}>
                                29 Июля 2024 - 4 Август 2024
                            </span>
                        </p>
                        <button key={uuid()} id={uuid()} className={styles.analyse_week_button}
                                onClick={(event) => on_analyse_week_click(event)}>
                            Анализировать
                        </button>
                    </div>
                    <div key={uuid()} className={styles.content}>
                        <div key={uuid()} className={styles.stats}>
                            <div key={uuid()} className={styles.stat}>
                                <p key={uuid()} className={styles.stat__value}>
                                    50%
                                </p>
                                <p key={uuid()} className={styles.stat__name}>
                                    Win rate<br/>
                                    Win 4/ 8
                                </p>
                            </div>
                            <div key={uuid()} className={styles.stat}>
                                <p key={uuid()} className={styles.stat__value + ' ' + styles.stat__positive}>
                                    $10.13
                                </p>
                                <p key={uuid()} className={styles.stat__name}>
                                    Net profit<br/>
                                    Volume $12,928.68
                                </p>
                            </div>
                            <div key={uuid()} className={styles.stat}>
                                <p key={uuid()} className={styles.stat__value}>
                                    2.22
                                </p>
                                <p key={uuid()} className={styles.stat__name}>
                                    Payoff ratio<br/>
                                    $4.62 / $2.08
                                </p>
                            </div>
                            <div key={uuid()} className={styles.stat}>
                                <p key={uuid()} className={styles.stat__value}>
                                    37.5%
                                </p>
                                <p key={uuid()} className={styles.stat__name}>
                                    Long trades (3)<br/>
                                    Short 62.5% (5)
                                </p>
                            </div>
                            <div key={uuid()} className={styles.stat}>
                                <p key={uuid()} className={styles.stat__value+' '+styles.stat__negative}>
                                    -12$.8
                                </p>
                                <p key={uuid()} className={styles.stat__name}>
                                    Commission<br/>
                                    Average $1.60
                                </p>
                            </div>
                        </div>
                        <div className={styles.week_comments}>
                            <textarea key={uuid()} id={uuid()} className={styles.comments_textarea}></textarea>
                        </div>
                    </div>
                </div>
            );
        }
        return weeks;
    };

    return (
        <div className={styles.block_weeks}>
            <div className={styles.navigator}>
                <button id={uuid()} className={styles.back_button}
                        onClick={(event) => on_back_button_click(event)}>
                    <img className={styles.back_button__img} src={img_arrow_left} alt='Back'/>
                </button>
                <p className={styles.weeks__title}>Январь 2024</p>
            </div>
            <div className={styles.weeks}>
                <div className={styles.scrollbar_top_shadow}/>
                <div className={styles.weeks_scrollbar}>
                    {create_weeks()}
                </div>
                <div className={styles.scrollbar_bottom_shadow}/>
            </div>
        </div>
    );
};

export default DiaryWeeks;
