import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {measure_text_width} from '../../../../static/js/textutils';
import styles from './mytrades.module.css';
import {start_updates, stop_updates, set_pagination, get_request_positions_data, set_positions_filter} from './controller';
import {add_handler, remove_handler, send_to_handlers} from '../../../../static/js/handlers';
import {reset_expanded_rows} from './controller';
import {set_row_checkbox_checked, reset_rows_checkbox_checked} from './controller';
import {Decimal} from "decimal.js";
import {decimal_to_string} from "../../../../static/js/numutils";
import TableRow from './tablerow';
import Header from './header';
import img_checkbox_checkmark from './static/images/checkbox-checkmark.svg';
import img_arrow_down from '../../../../static/images/arrow-1-down-white.svg';
import img_arrow_left from "../../../../static/images/arrow-1-left-white.svg";
import img_arrow_right from "../../../../static/images/arrow-1-right-white.svg";


const MyTrades = () => {
    const [page_index, set_page_index] = useState(0);
    const [page_count, set_page_count] = useState(0);
    const [all_count, set_all_count] = useState(0);
    const [rows_per_page, set_rows_per_page] = useState(20);
    const [stats_positions_count, set_stats_positions_count] = useState(new Decimal(0));
    const [stats_profit_percents, set_stats_profit_percents] = useState(new Decimal(0));
    const [stats_pnl_value, set_stats_pnl_value] = useState(new Decimal(0));
    const [stats_net_profit_value, set_stats_net_profit_value] = useState(new Decimal(0));
    const [stats_volume_value, set_stats_volume_value] = useState(new Decimal(0));
    const [stats_fee_value, set_stats_fee_value] = useState(new Decimal(0));
    const [stats_funding_value, set_stats_funding_value] = useState(new Decimal(0));
    const [rows_data_uuid, set_rows_data_uuid] = useState(uuid());
    const [rows_uuid, set_rows_uuid] = useState(uuid());
    const rows_data = useRef(null);

    useEffect(() => {
        window.addEventListener('click', on_window_click);
        set_positions_filter('0:0');
        set_pagination(0, rows_per_page);
        start_updates();
        add_handler('my_trades', 'on_positions_updated', on_positions_updated);
        add_handler('my_trades_table_row', 'on_row_expanded', on_row_expanded);
        add_handler('my_trades_header', 'on_filter_changed', on_filter_changed);
        on_positions_updated(null);
        return () => {
            window.removeEventListener('click', on_window_click);
            stop_updates();
            remove_handler('my_trades', 'on_positions_updated', on_positions_updated);
            remove_handler('my_trades_table_row', 'on_row_expanded', on_row_expanded);
            remove_handler('my_trades_header', 'on_filter_changed', on_filter_changed);
        };
    }, []);

    useLayoutEffect(() => {
        update_all_column_width();
    }, [rows_uuid]);

    const on_positions_updated = (p) => {
        if (!get_request_positions_data())
            return;
        set_page_index(get_request_positions_data().start);
        set_page_count(get_request_positions_data().count);
        set_all_count(get_request_positions_data().all_count);
        set_stats(get_request_positions_data());
        if (!rows_data.current || rows_data.current.length !== get_request_positions_data().positions.length) {
            reset_expanded_rows();
            rows_data.current = create_table_rows();
            set_rows_data_uuid(uuid());
        }
    };

    const on_row_expanded = (p) => {
        update_all_column_width();
        if (!p.expanded)
            return;
        const rows = document.querySelectorAll(`[class*="${styles.table_row}"]`);
        for(const row of rows) {
            if (Number(row.id) !== p.index)
                continue;
            const table = row.closest(`[class*="${styles.table}"]`);
            if (!table)
                break;
            if (table.scrollTop > row.offsetTop - 50) {
                table.scrollTo({
                    top: Number(row.offsetTop - 50),
                });
            } else if ((table.scrollTop + table.offsetHeight) < (row.offsetTop + row.offsetHeight)) {
                if (table.offsetHeight - 50 >= row.offsetHeight) {
                    table.scrollTo({
                        top: Number(row.offsetTop - (table.offsetHeight - row.offsetHeight) + 20),
                    });
                } else {
                    table.scrollTo({
                        top: Number(row.offsetTop - 50),
                    });
                }
            }
            break;
        }
    };

    const on_filter_changed = (p) => {
        set_positions_filter(p.filter);
    };

    const set_stats = (positions_data) => {
        let positions_count = new Decimal(0);
        let profit_percents = new Decimal(0);
        let pnl_value = new Decimal(0);
        let net_profit_value = new Decimal(0);
        let volume_value = new Decimal(0);
        let fee_value = new Decimal(0);
        let funding_value = new Decimal(0);
        for(const position of positions_data.positions) {
            positions_count = positions_count.add(1);
            if (position.profit_percents !== null)
                profit_percents = profit_percents.add(position.profit_percents);
            if (position.pnl_value !== null)
                pnl_value = pnl_value.add(position.pnl_value);
            if (position.net_profit_value !== null)
                net_profit_value = net_profit_value.add(position.net_profit_value);
            if (position.volume_value !== null)
                volume_value = volume_value.add(position.volume_value);
            if (position.fee_value !== null)
                fee_value = fee_value.add(position.fee_value);
            if (position.funding_value !== null)
                funding_value = funding_value.add(position.funding_value);
        }
        set_stats_positions_count(positions_count);
        set_stats_profit_percents(profit_percents);
        set_stats_pnl_value(pnl_value);
        set_stats_net_profit_value(net_profit_value);
        set_stats_volume_value(volume_value);
        set_stats_fee_value(fee_value);
        set_stats_funding_value(funding_value);
    };

    const create_table_rows = () => {
        let rows = [];
        if (!get_request_positions_data())
            return rows;
        for (let i = 0; i < get_request_positions_data().positions.length; ++i) {
            rows.push(
                <div key={`pos-row-${i}`} className={styles.table_row_block}>
                    {rows.length > 0 ? <div key={`pos-row-hl-${i}`} className={styles.row_hor_line}/> : null}
                    <TableRow key={`pos-row-data-${i}`} index={i} set_rows_uuid={set_rows_uuid}/>
                </div>
            );
        }
        return rows;
    };

    const on_window_click = (event) => {
        if (!(event.target.closest(`[class*=${styles.dropdown}]`)))
            on_close_rows_per_page_dropdown();
    };

    const on_set_main_ticker_checkbox_checked = (checked) => {
        const ticker_block = document.querySelector(`[class*="${styles.table_column__ticker_block}"]`);
        if (!ticker_block)
            return;
        const checkbox = ticker_block.querySelector(`[class*="${styles.checkbox__input}"]`);
            if (checkbox)
                checkbox.checked = checked;
    };

    const on_main_ticker_checkbox_checked = (event) => {
        on_set_main_ticker_checkbox_checked(event.target.checked);
        for(let i = 0; i < rows_per_page; ++i)
            set_row_checkbox_checked(i, event.target.checked);
        send_to_handlers('my_trades_row_data', 'on_row_checkbox_changed', {});
    };

    const on_rows_per_page_dropdown_click = (event) => {
        const dropdown = event.target.closest(`[class*="${styles.dropdown}"]`);
        if (!dropdown)
            return;
        event.stopPropagation();
        const button = dropdown.querySelector(`[class*="${styles.dropdown_button}"]`);
        if (button) {
            if (button.classList.contains(styles.dropdown_button__checked))
                return on_close_rows_per_page_dropdown();
            else
                button.classList.add(styles.dropdown_button__checked);
        }
        const dropdown_content = dropdown.querySelector(`[class*="${styles.dropdown_content}"]`);
        if (dropdown_content) {
            if (dropdown_content.classList.contains(styles.dropdown_content__visible)) {
                return on_close_rows_per_page_dropdown();
            } else {
                dropdown_content.classList.add(styles.dropdown_content__visible);
                const items = dropdown_content.querySelectorAll(`[class*="${styles.dropdown_content__item}"]`);
                for(const item of items) {
                    item.classList.remove(styles.dropdown_content__item__selected);
                    if (Number(item.textContent) === rows_per_page) {
                        item.classList.add(styles.dropdown_content__item__selected);
                    }
                }
            }
        }
    };

    const on_close_rows_per_page_dropdown = () => {
        const button = document.querySelector(`[class*="${styles.dropdown_button}"]`);
        if (button)
            button.classList.remove(styles.dropdown_button__checked);
        const dropdown_content = document.querySelector(`[class*="${styles.dropdown_content}"]`);
        if (dropdown_content)
            dropdown_content.classList.remove(styles.dropdown_content__visible);
    };

    const on_rows_per_page_dropdown_item_click = (event) => {
        const item = event.target.closest(`[class*="${styles.dropdown_content__item}"]`);
        if (!item)
            return;
        event.stopPropagation();
        on_close_rows_per_page_dropdown();
        const new_rows_per_page = Number(event.target.textContent)
        if (rows_per_page === new_rows_per_page)
            return;
        set_rows_per_page(new_rows_per_page);
        rows_data.current = null;
        on_set_main_ticker_checkbox_checked(false);
        reset_expanded_rows();
        reset_rows_checkbox_checked();
        set_pagination(0, new_rows_per_page);
    };

    const on_prev_page_click = (event) => {
        rows_data.current = null;
        on_set_main_ticker_checkbox_checked(false);
        reset_expanded_rows();
        reset_rows_checkbox_checked();
        set_pagination(Math.max(0, page_index - rows_per_page), rows_per_page);
    };

    const on_next_page_click = (event) => {
        rows_data.current = null;
        on_set_main_ticker_checkbox_checked(false);
        reset_expanded_rows();
        reset_rows_checkbox_checked();
        let new_page_index = page_index + rows_per_page
        if (new_page_index >= all_count) {
            new_page_index = Math.trunc(all_count / rows_per_page) * (all_count % rows_per_page ? rows_per_page : rows_per_page - 1);
        }
        set_pagination(new_page_index, rows_per_page);
    };

    const update_all_column_width = (padding_left = 0, padding_right = 15) => {
        update_column_width(styles.table_column__ticker, padding_left, padding_right);
        update_column_width(styles.table_column__exchange_name, padding_left, padding_right);
        update_column_width(styles.table_column__category, padding_left, padding_right);
        update_column_width(styles.table_column__api_key, padding_left, padding_right);
        update_column_width(styles.table_column__leverage, padding_left, padding_right);
        update_column_width(styles.table_column__entry_time, padding_left, padding_right);
        update_column_width(styles.table_column__avg_entry_price, padding_left, padding_right);
        update_column_width(styles.table_column__exit_time, padding_left, padding_right);
        update_column_width(styles.table_column__avg_exit_price, padding_left, padding_right);
        update_column_width(styles.table_column__duration, padding_left, padding_right);
        update_column_width(styles.table_column__side, padding_left, padding_right);
        update_column_width(styles.table_column__percent, padding_left, padding_right);
        update_column_width(styles.table_column__pnl, padding_left, padding_right);
        update_column_width(styles.table_column__net_profit, padding_left, padding_right);
        update_column_width(styles.table_column__qty, padding_left, padding_right);
        update_column_width(styles.table_column__peak_qty, padding_left, padding_right);
        update_column_width(styles.table_column__volume, padding_left, padding_right);
        update_column_width(styles.table_column__commission, padding_left, padding_right);
        update_column_width(styles.table_column__funding, padding_left, padding_right);
    };

    const update_column_width = (class_id, padding_left = 0, padding_right = 0) => {
        const items = document.querySelectorAll(`[class*="${class_id}"]`);
        let max_width = Number.MIN_SAFE_INTEGER;
        let items_to_change = [];
        for(const item of items) {
            let text = item.querySelector(`[class*="${styles.table_row_item__text}"]`);
            if (!text) {
                text = item.querySelector(`[class*="${styles.table_header__text}"]`);
                if (!text)
                    continue;
            }
            const width = measure_text_width(text);
            if (width > max_width)
                max_width = width;
            items_to_change.push(item);
        }
        const max_width_str = `${max_width}px`;
        const padding_left_str = `${padding_left}px`;
        const padding_right_str = `${padding_right}px`
        for(const item of items_to_change) {
            if (item.style.width !== max_width_str)
                item.style.width = max_width_str;
            if (item.style.minWidth !== max_width_str)
                item.style.minWidth = max_width_str;
            if (item.style.maxWidth !== max_width_str)
                item.style.maxWidth = max_width_str;
            if (item.style.paddingLeft !== padding_left_str)
                item.style.paddingLeft = padding_left_str;
            if (item.style.paddingRight !== padding_right_str)
                item.style.paddingRight = padding_right_str;
        }
    };

    return (
        <div className={styles.block}>
            <Header />
            <main className={styles.main}>
                <div className={styles.table}>
                    <div className={styles.table_header}>
                        <div className={styles.table_column__ticker_checkbox}>
                            <div className={styles.table_column__ticker_block}>
                                <label className={styles.checkbox_block}>
                                    <input id={uuid()} className={styles.checkbox__input} type="checkbox"
                                           onClick={(event) => on_main_ticker_checkbox_checked(event)}/>
                                    <img className={styles.checkbox__checkmark} src={img_checkbox_checkmark} alt='Checkmark'/>
                                </label>
                            </div>
                        </div>
                        <div className={styles.table_column__ticker}>
                            <p className={styles.table_header__text}>Тикер</p>
                        </div>
                        <div className={styles.table_column__exchange_name}>
                            <p className={styles.table_header__text}>Биржа</p>
                        </div>
                        <div className={styles.table_column__category}>
                            <p className={styles.table_header__text}>Категория</p>
                        </div>
                        <div className={styles.table_column__api_key}>
                            <p className={styles.table_header__text}>API ключ</p>
                        </div>
                        <div className={styles.table_column__entry_reason}>
                            <p className={styles.table_header__text}>Причина входа</p>
                        </div>
                        <div className={styles.table_column__extra_info}>
                            <p className={styles.table_header__text}>Доп. информация</p>
                        </div>
                        <div className={styles.table_column__leverage}>
                            <p className={styles.table_header__text}>Плече</p>
                        </div>
                        <div className={styles.table_column__entry_time}>
                            <p className={styles.table_header__text}>Время входа</p>
                        </div>
                        <div className={styles.table_column__avg_entry_price}>
                            <p className={styles.table_header__text}>Средняя цена входа</p>
                        </div>
                        <div className={styles.table_column__exit_time}>
                            <p className={styles.table_header__text}>Время выхода</p>
                        </div>
                        <div className={styles.table_column__avg_exit_price}>
                            <p className={styles.table_header__text}>Средняя цена выхода</p>
                        </div>
                        <div className={styles.table_column__duration}>
                            <p className={styles.table_header__text}>Длительность</p>
                        </div>
                        <div className={styles.table_column__side}>
                            <p className={styles.table_header__text}>Сторона</p>
                        </div>
                        <div className={styles.table_column__percent}>
                            <p className={styles.table_header__text}>Процент (%)</p>
                        </div>
                        <div className={styles.table_column__pnl}>
                            <p className={styles.table_header__text}>PNL ($)</p>
                        </div>
                        <div className={styles.table_column__net_profit}>
                            <p className={styles.table_header__text}>Прибыль ($)</p>
                        </div>
                        <div className={styles.table_column__qty}>
                            <p className={styles.table_header__text}>Оборот</p>
                        </div>
                        <div className={styles.table_column__peak_qty}>
                            <p className={styles.table_header__text}>Макс. объем</p>
                        </div>
                        <div className={styles.table_column__volume}>
                            <p className={styles.table_header__text}>Объем ($)</p>
                        </div>
                        <div className={styles.table_column__commission}>
                            <p className={styles.table_header__text}>Комиссия ($)</p>
                        </div>
                        <div className={styles.table_column__funding}>
                            <p className={styles.table_header__text}>Фандинг ($)</p>
                        </div>
                    </div>
                    <div className={styles.scrollbar_top_shadow}/>
                    <div key={rows_data_uuid} className={styles.table_rows}>
                        {rows_data.current}
                    </div>
                </div>
            </main>
            <footer className={styles.footer}>
                <div className={styles.footer_section_1}>
                    <div className={styles.footer_stats}>
                        <p className={styles.footer_stats__text}>
                            {decimal_to_string(stats_positions_count, 1)} Позиций
                        </p>
                        <span className={styles.footer_stats__separator}/>
                        <p className={styles.footer_stats__text}>
                            {decimal_to_string(stats_profit_percents, 2, '%', 'r')} Процент (%)
                        </p>
                        <span className={styles.footer_stats__separator}/>
                        <p className={styles.footer_stats__text}>
                            {decimal_to_string(stats_pnl_value, 2, '$')} PNL ($)
                        </p>
                        <span className={styles.footer_stats__separator}/>
                        <p className={styles.footer_stats__text}>
                            {decimal_to_string(stats_net_profit_value, 2, '$')} Прибыль ($)
                        </p>
                        <span className={styles.footer_stats__separator}/>
                        <p className={styles.footer_stats__text}>
                            {decimal_to_string(stats_volume_value, 2, '$')} Объем ($)
                        </p>
                        <span className={styles.footer_stats__separator}/>
                        <p className={styles.footer_stats__text}>
                            {decimal_to_string(stats_fee_value, 2, '$')} Комиссия ($)
                        </p>
                        <span className={styles.footer_stats__separator}/>
                        <p className={styles.footer_stats__text}>
                            {decimal_to_string(stats_funding_value, 2, '$')} Фандинг ($)
                        </p>
                        <span className={styles.footer_stats__separator}/>
                    </div>
                    <div className={styles.rows_per_page}>
                        <p className={styles.rows_per_page__text}>Строк на странице</p>
                        <div className={styles.dropdown}>
                            <button className={styles.dropdown_button}
                                    onClick={(event) => on_rows_per_page_dropdown_click(event)}>
                                {rows_per_page}
                                <img className={styles.dropdown_button__arrow_down} src={img_arrow_down} alt='Open'/>
                            </button>
                            <div className={styles.dropdown_content}>
                                <ul className={styles.dropdown_content__list}>
                                    <li className={styles.dropdown_content__item}
                                        onClick={(event) => on_rows_per_page_dropdown_item_click(event)}>
                                        10
                                    </li>
                                    <li className={styles.dropdown_content__item}
                                        onClick={(event) => on_rows_per_page_dropdown_item_click(event)}>
                                        20
                                    </li>
                                    <li className={styles.dropdown_content__item}
                                        onClick={(event) => on_rows_per_page_dropdown_item_click(event)}>
                                        30
                                    </li>
                                    <li className={styles.dropdown_content__item}
                                        onClick={(event) => on_rows_per_page_dropdown_item_click(event)}>
                                        50
                                    </li>
                                    <li className={styles.dropdown_content__item}
                                        onClick={(event) => on_rows_per_page_dropdown_item_click(event)}>
                                        100
                                    </li>
                                    <li className={styles.dropdown_content__item} onClick={(event) => on_rows_per_page_dropdown_item_click(event)}>
                                        150
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.page_switcher}>
                    <img className={styles.page_switcher__img} src={img_arrow_left} alt='<'
                         onClick={(event) => on_prev_page_click(event)}/>
                    <p className={styles.page_switcher__text}>
                        {page_count > 0 ? page_index + 1 : 0} - {page_index + page_count}
                        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                        {all_count}
                    </p>
                    <img className={styles.page_switcher__img} src={img_arrow_right} alt='>'
                         onClick={(event) => on_next_page_click(event)}/>
                </div>
            </footer>
        </div>
    );
};

export default MyTrades;
