export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function swait(timeout_ms, interval_ms, interrupt, callback) {
    if (interrupt() || (timeout_ms < 1) || (interval_ms < 1)) {
        callback();
        return;
    }
    do {
        if (timeout_ms < interval_ms) {
            await sleep(timeout_ms);
            break;
        }
        await sleep(interval_ms);
        timeout_ms -= interval_ms;
    } while ((timeout_ms > 0) && !interrupt())
    callback();
}
