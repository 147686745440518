import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import styles from './account.module.css';
import {check_is_auth} from '../auth/common';
import Header from './header';
import Analytics from './components/analytics/analytics';
import MyTrades from "./components/mytrades/mytrades";
import Diary from "./components/diary/diary";
import ApiKeys from './components/apikeys/apikeys';
import Profile from "./components/profile/profile";
import axios from "axios";
import {get_axios_config} from "../../static/js/network";


const Account = () => {
    const navigate = useNavigate();
    const [user_info, set_user_info] = useState(null);

    useLayoutEffect(() => {
        check_account_auth();
        if (!user_info)
            get_user_info();
    }, []);

    useEffect(() => {
        const interval_id = setInterval(ping, 30000);
        return () => clearInterval(interval_id);
    }, []);

    const check_account_auth = async () => {
        if (!(await check_is_auth()))
            return navigate('/');
        ping();
    };

    const ping = async() => {
        axios.get('/api/account/ping', get_axios_config('get'))
            .catch((error) => {
                console.error(error);
            });
    }

    const get_user_info = async () => {
        axios.get('/api/account/profile/getuserinfo/', get_axios_config('get'))
            .then((response) => {
                if (response.status === 200)
                    set_user_info(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className={styles.page}>
            <div className={styles.page_background}>
                <div className={styles.content_background}>
                    <div className={styles.content}>
                        <Header user_info={user_info} />
                        <main className={styles.main}>
                            <Routes>
                                <Route path='/' element={<Navigate to={'/account/analytics'} />}/>
                                <Route path='/analytics' element={<Analytics />}/>
                                <Route path='/mytrades' element={<MyTrades />}/>
                                <Route path='/diary/*' element={<Diary />}/>
                                <Route path='/apikeys' element={<ApiKeys />}/>
                                <Route path='/profile' element={<Profile user_info={user_info}/>}/>
                                <Route path='*' element={<Navigate to={'/account'} />}/>
                            </Routes>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
