import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import axios from 'axios';
import {get_axios_config} from '../../../../static/js/network';
import {sleep} from '../../../../static/js/sleep';
import {send_to_handlers} from '../../../../static/js/handlers';
import MarketStatus from './merketstatus';
import styles from './mytrades.module.css';
import img_market_closed from './static/images/market-closed.svg';
import img_connected_key from './static/images/connected-key.svg';


const Header = () => {
    const [filter_today, set_filter_today] = useState(false);
    const [filter_yesterday, set_filter_yesterday] = useState(false);
    const [filter_3_days, set_filter_3_days] = useState(false);
    const [filter_7_days, set_filter_7_days] = useState(false);
    const [filter_14_days, set_filter_14_days] = useState(false);
    const [filter_30_days, set_filter_30_days] = useState(false);
    const [filter_90_days, set_filter_90_days] = useState(false);
    const [count_connected_keys, set_count_connected_keys] = useState(0);

    useEffect(() => {
        on_load_count_connected_keys();
    }, []);

    useEffect(() => {
        let filter = '0:0';
        if (filter_today)
            filter = '0:1';
        else if (filter_yesterday)
            filter = '1:2';
        else if (filter_3_days)
            filter = '0:3';
        else if (filter_7_days)
            filter = '0:7';
        else if (filter_14_days)
            filter = '0:14';
        else if (filter_30_days)
            filter = '0:30';
        else if (filter_90_days)
            filter = '0:90';
        send_to_handlers('my_trades_header', 'on_filter_changed', {filter: filter});
    }, [filter_today, filter_yesterday, filter_3_days, filter_7_days, filter_14_days, filter_30_days, filter_90_days]);

    const on_clear_filters = (event) => {
        set_filter_today(false);
        set_filter_yesterday(false);
        set_filter_3_days(false);
        set_filter_7_days(false);
        set_filter_14_days(false);
        set_filter_30_days(false);
        set_filter_90_days(false);
    };

    const on_filter_today_click = (event) => {
        on_clear_filters(null);
        set_filter_today(true);
    };

    const on_filter_yesterday_click = (event) => {
        on_clear_filters(null);
        set_filter_yesterday(true);
    };

    const on_filter_3_days_click = (event) => {
        on_clear_filters(null);
        set_filter_3_days(true);
    };

    const on_filter_7_days_click = (event) => {
        on_clear_filters(null);
        set_filter_7_days(true);
    };

    const on_filter_14_days_click = (event) => {
        on_clear_filters(null);
        set_filter_14_days(true);
    };

    const on_filter_30_days_click = (event) => {
        on_clear_filters(null);
        set_filter_30_days(true);
    };

    const on_filter_90_days_click = (event) => {
        on_clear_filters(null);
        set_filter_90_days(true);
    };

    const on_load_count_connected_keys = () => {
        axios.get(`/api/exchanges/countconnectedkeys/`, get_axios_config('get'))
            .then(async (response) => {
                if (response.status === 200) {
                    set_count_connected_keys(response.data.count);
                }
            })
            .catch(async (error) => {
                console.error(error);
                await sleep(5000);
                on_load_count_connected_keys();
            });
    };

    return (
        <header className={styles.header}>
            <div className={styles.header_sections}>
                <div className={styles.header_section_1}>
                    <div className={styles.header_section_2}>
                        <p className={styles.title}>Список трейдов</p>
                        <div className={styles.header__ver_line}></div>
                        <MarketStatus />
                    </div>
                    <div className={styles.header_section_3}>
                        <button className={styles.filter__clear_button} onClick={(event) => on_clear_filters(event)}>
                            Очистить фильтры
                        </button>
                        <img className={styles.connected_keys__icon} src={img_connected_key} alt='Key'/>
                        <p className={styles.connected_keys__text}>
                            {count_connected_keys} ключей подключенных к бирже
                        </p>
                    </div>
                </div>
                <div className={styles.filters}>
                    <button id={'0:1'} className={styles.filter__button + ' ' +
                            (filter_today ? styles.filter__button__checked : styles.filter__button__unchecked)}
                            onClick={(event) => on_filter_today_click(event)}>
                        Сегодня
                    </button>
                    <button id={'1:2'} className={styles.filter__button + ' ' +
                            (filter_yesterday ? styles.filter__button__checked : styles.filter__button__unchecked)}
                            onClick={(event) => on_filter_yesterday_click(event)}>
                        Вчера
                    </button>
                    <button id={'0:3'} className={styles.filter__button + ' ' +
                            (filter_3_days ? styles.filter__button__checked : styles.filter__button__unchecked)}
                            onClick={(event) => on_filter_3_days_click(event)}>
                        3 дня
                    </button>
                    <button id={'0:7'} className={styles.filter__button + ' ' +
                            (filter_7_days ? styles.filter__button__checked : styles.filter__button__unchecked)}
                            onClick={(event) => on_filter_7_days_click(event)}>
                        7 дней
                    </button>
                    <button id={'0:14'} className={styles.filter__button + ' ' +
                            (filter_14_days ? styles.filter__button__checked : styles.filter__button__unchecked)}
                            onClick={(event) => on_filter_14_days_click(event)}>
                        14 дней
                    </button>
                    <button id={'0:30'} className={styles.filter__button + ' ' +
                            (filter_30_days ? styles.filter__button__checked : styles.filter__button__unchecked)}
                            onClick={(event) => on_filter_30_days_click(event)}>
                        30 дней
                    </button>
                    <button id={'0:90'} className={styles.filter__button + ' ' +
                            (filter_90_days ? styles.filter__button__checked : styles.filter__button__unchecked)}
                            onClick={(event) => on_filter_90_days_click(event)}>
                        90 дней
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;
