import axios from 'axios';
import {Decimal} from 'decimal.js';
import {get_axios_config} from '../../../../static/js/network';
import {sleep, swait} from '../../../../static/js/sleep';
import {send_to_handlers} from '../../../../static/js/handlers';


let update_started = false;
let stop_requests = false;
let update_positions_uuid = null;
let request_positions_uuid = null;
let request_positions_data = null;
let need_request_positions = false;
let page_index = 0;
let rows_per_page = 20;
let positions_filter = '0:0';
let expanded_rows = {};
let rows_checkbox_checked = {};


export function start_updates() {
    stop_requests = false;
    if (update_started)
        return;
    update_started = true;
    update_positions();
    request_positions();
}

export function stop_updates() {
    if (update_started)
        stop_requests = true;
}

export function set_pagination(in_page_index, in_rows_per_page) {
    if (page_index === in_page_index && rows_per_page === in_rows_per_page)
        return;
    page_index = in_page_index;
    rows_per_page = in_rows_per_page;
    need_request_positions = true;
}

export function set_positions_filter(filter) {
    if (positions_filter === filter)
        return;
    positions_filter = filter
    need_request_positions = true;
}

export function get_request_positions_data() {
    return request_positions_data;
}

function can_continue() {
    if (update_started && !stop_requests)
        return true;
    update_started = false;
    stop_requests = false;
    return false;
}

async function update_positions() {
    if (!can_continue())
        return;
    axios.get(`/api/trades/updatepositions/`, get_axios_config('get'))
        .then(async (response) => {
            if (response.status === 200) {
                update_positions_uuid = response.data.uuid;
                await sleep(10000);
                return take_update();
            }
            await sleep(3000);
            return update_positions();
        })
        .catch(async (error) => {
            console.error(error);
            await sleep(3000);
            return update_positions();
        });
}

async function take_update() {
    axios.get(`/api/trades/takeresult/${update_positions_uuid}/`, get_axios_config('get'))
        .then(async (response) => {
            if (response.status === 200 && response.data.status) {
                console.log(`[${(new Date()).toLocaleString()}]: updating positions is done`);
                update_positions_uuid = null;
                await sleep(60000);
                return update_positions();
            }
            await sleep(3000);
            return update_positions();
        })
        .catch(async (error) => {
            if (error.status === 423) {
                await sleep(10000);
                return take_update();
            }
            console.error(error);
            await sleep(3000);
            return update_positions();
        });
}

async function request_positions(){
    need_request_positions = false;
    if (!can_continue())
        return;
    const filters = positions_filter.split(':');
    const fs = Number(filters[0]);
    const fe = Number(filters[1]);
    axios.get(`/api/trades/requestpositions/${page_index}/${rows_per_page}/${fs}/${fe}/`, get_axios_config('get'))
        .then(async (response) => {
            if (response.status === 200) {
                request_positions_uuid = response.data.uuid;
                await sleep(128);
                return take_positions();
            }
            await sleep(3000);
            return request_positions();
        })
        .catch(async (error) => {
            console.error(error);
            await sleep(3000);
            return request_positions();
        });
}

async function take_positions(){
    axios.get(`/api/trades/takeresult/${request_positions_uuid}/`, get_axios_config('get'))
        .then(async (response) => {
            if (response.status === 200) {
                let positions = [];
                for(const position of response.data.positions) {
                    let orders = []
                    for(const order of position.orders) {
                        if (order.type === 'funding') {
                            orders.push({
                                timestamp_ms: order.timestamp_ms,
                                type: order.type,
                                fee_value: (order.fee_value ? new Decimal(order.fee_value) : null),
                            });
                        } else {
                            orders.push({
                                timestamp_ms: order.timestamp_ms,
                                type: order.type,
                                side: order.side,
                                qty: (order.qty ? new Decimal(order.qty) : null),
                                price: (order.price ? new Decimal(order.price) : null),
                                volume_value: (order.volume_value ? new Decimal(order.volume_value) : null),
                                pnl_value: (order.pnl_value ? new Decimal(order.pnl_value) : null),
                                fee_value: (order.fee_value ? new Decimal(order.fee_value) : null),
                            });
                        }
                    }
                    positions.push({
                        id: position.id,
                        timestamp_ms: position.timestamp_ms,
                        exchange_name: position.exchange_name,
                        category: position.category,
                        api_key: position.api_key,
                        closed: position.closed,
                        ticker: position.ticker,
                        side: position.side,
                        entry_timestamp_ms: position.entry_timestamp_ms,
                        exit_timestamp_ms: position.exit_timestamp_ms,
                        duration_ms: position.duration_ms,
                        leverage: (position.leverage ? new Decimal(position.leverage) : null),
                        avg_price_entry: (position.avg_price_entry ? new Decimal(position.avg_price_entry) : null),
                        avg_price_exit: (position.avg_price_exit ? new Decimal(position.avg_price_exit) : null),
                        profit_percents: (position.profit_percents ? new Decimal(position.profit_percents) : null),
                        funding_value: (position.funding_value ? new Decimal(position.funding_value) : null),
                        pnl_value: (position.pnl_value ? new Decimal(position.pnl_value) : null),
                        net_profit_value: (position.net_profit_value ? new Decimal(position.net_profit_value) : null),
                        net_deposit_value: (position.net_deposit_value ? new Decimal(position.net_deposit_value) : null),
                        qty: (position.qty ? new Decimal(position.qty) : null),
                        peak_qty: (position.peak_qty ? new Decimal(position.peak_qty) : null),
                        volume_value: (position.volume_value ? new Decimal(position.volume_value) : null),
                        fee_value: (position.fee_value ? new Decimal(position.fee_value) : null),
                        orders: orders,
                        entry_reason: position.entry_reason,
                        has_description: position.has_description,
                        has_conclusion: position.has_conclusion,
                    });
                }
                page_index = response.data.start;
                request_positions_data = {
                    all_count: response.data.all_count,
                    start: response.data.start,
                    count: response.data.count,
                    positions: positions,
                };
                request_positions_uuid = null;
                console.log(`[${(new Date()).toLocaleString()}]: requesting positions is done`);
                send_to_handlers('my_trades', 'on_positions_updated', {});
                return await swait(30000, 250, () => need_request_positions, request_positions);
            }
            await sleep(3000);
            return request_positions();
        })
        .catch(async (error) => {
            if (error.status === 423) {
                await sleep(1000);
                return take_positions();
            }
            console.error(error);
            await sleep(3000);
            return request_positions();
        });
}

export function set_expanded_row(index, expanded) {
    expanded_rows[index] = expanded;
}

export function get_expanded_row(index) {
    if (expanded_rows[index] === undefined)
        expanded_rows[index] = false;
    return expanded_rows[index];
}

export function reset_expanded_rows() {
    expanded_rows = {};
    send_to_handlers('my_trades_table_row', 'on_toggle_expand_row', {
            index: null,
        });
}

export function set_row_checkbox_checked(index, checked) {
    rows_checkbox_checked[index] = checked;
}

export function get_row_checkbox_checked(index) {
    if (rows_checkbox_checked[index] === undefined)
        rows_checkbox_checked[index] = false;
    return rows_checkbox_checked[index];
}

export function reset_rows_checkbox_checked() {
    rows_checkbox_checked = {};
    send_to_handlers('my_trades_row_data', 'on_row_checkbox_changed', {});
}

export function on_textarea_load(get_method, set_text, kwargs) {
    const position_id = kwargs['get_position_id']();
    if (!get_method || (position_id === null))
        return;
    axios.get(`/api/trades/${get_method}/${position_id}/`, get_axios_config('get'))
        .then((response) => {
            if (response.status === 200)
                set_text(response.data.text);
        })
        .catch((error) => {
            console.error(error);
        });
}

export function on_textarea_write(write_method, current_text, commit_text, commited_text, kwargs) {
    const position_id = kwargs['get_position_id']();
    if (!write_method || (position_id === null) || (current_text.current === commited_text.current))
        return;
    commit_text.current = current_text.current;
    const data = JSON.stringify({
        position_id: position_id,
        text: commit_text.current,
    });
    axios.post(`/api/trades/${write_method}/`, data, get_axios_config('post'))
        .then((response) => {
            if (response.status === 200 && response.data.uuid)
                commited_text.current = commit_text.current;
        })
        .catch((error) => {
            console.error(error);
        });
}
