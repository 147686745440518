let handlers_map = {};


function get_handlers(container, namespace, event) {
    if (container[namespace] && container[namespace].events[event])
        return container[namespace].events[event]
    return [];
}

function impl_add_handler(container, namespace, event, handler) {
    if (!container[namespace])
        container[namespace] = { events: {} };
    if (!container[namespace].events[event])
        container[namespace].events[event] = []
    if (!container[namespace].events[event].includes(handler))
        container[namespace].events[event].push(handler);
}

function impl_remove_handler(container, namespace, event, handler) {
    if (container[namespace] && container[namespace].events[event]) {
        const index = container[namespace].events[event].indexOf(handler);
        if (index >= 0)
            container[namespace].events[event].splice(index, 1);
    }
}

function impl_remove_handlers(container, namespace, event) {
    if (container[namespace] && container[namespace].events[event])
        container[namespace].events[event] = []
}

export function add_handler(namespace, event, handler) {
    impl_add_handler(handlers_map, namespace, event, handler);
}

export function remove_handler(namespace, event, handler) {
    impl_remove_handler(handlers_map, namespace, event, handler);
}

export function remove_handlers(namespace, event) {
    impl_remove_handlers(handlers_map, namespace, event);
}

export function send_to_handlers(namespace, event, props) {
    for (const handler of get_handlers(handlers_map, namespace, event))
        handler(props);
}
