import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import Plotly from 'plotly.js-dist-min';
import styles from './analytics.module.css'
import img_arrow_left from '../../../../static/images/arrow-1-left-white.svg';
import img_arrow_right from '../../../../static/images/arrow-1-right-white.svg';


const Analytics = () => {
    const navigate = useNavigate();
    const [current_date, set_current_date] = useState(new Date())
    const [calendar_data, set_calendar_data] = useState(null);

    useLayoutEffect(() => {
        if (!calendar_data)
            set_calendar_data(generate_calendar(current_date.getFullYear(),current_date.getMonth()));
        draw_plot();
    }, [current_date]);

    useEffect(() => {
        set_calendar_data(generate_calendar(current_date.getFullYear(),current_date.getMonth()));
    }, [current_date]);

    Date.prototype.addDays = function(days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    Date.prototype.addMonths = function(months) {
        let date = new Date(this.valueOf());
        date.setMonth(date.getMonth() + months);
        return date;
    }

    const draw_plot = () => {
        Plotly.newPlot('chart',
            {
                data: [{
                    type : 'scatter',
                    mode : 'lines',
                    x: [0, 3, 8, 10, 15, 18, 20],
                    y: [0, 2, 3, 4, -2, -3, 0],
                    line: {
                        color: '#087EBD',
                        shape: 'spline',
                        smoothing: 1.0,
                    },
                    fill: 'tozeroy',
                    fillcolor: '#174261'
                }],
                layout: {
                    autosize: true,
                    margin: {
                        autoexpand: true,
                        pad: 10,
                        l: 40, r: 25, b: 30, t: 27
                    },
                    paper_bgcolor: '#1D2839',
                    plot_bgcolor: '#1D2839',
                    xaxis: {
                        color: `${getComputedStyle(document.body).getPropertyValue('--account-text-color-white')}`,
                        automargin: true
                    },
                    yaxis: {
                        color: `${getComputedStyle(document.body).getPropertyValue('--account-text-color-white')}`,
                        automargin: true
                    },
                    font: {
                        color: `${getComputedStyle(document.body).getPropertyValue('--account-text-color-white')}`
                    }
                }
            });
    }

    const on_prev_month_click = (event) => {
        set_current_date(current_date.addMonths(-1))
    }

    const on_next_month_click = (event) => {
        set_current_date(current_date.addMonths(1))
    }

    const on_details_click = (event) => {
        const item_1 = document.querySelector(`[class*="${styles.switcher__item_1}"]`);
        const item_2 = document.querySelector(`[class*="${styles.switcher__item_2}"]`);
        if (!item_1 || !item_2)
            return;
        item_1.classList.remove(styles.switcher__item__unchecked);
        item_1.classList.add(styles.switcher__item__checked)
        item_2.classList.remove(styles.switcher__item__checked);
        item_2.classList.add(styles.switcher__item__unchecked)
    }

    const on_total_click = (event) => {
        const item_1 = document.querySelector(`[class*="${styles.switcher__item_1}"]`);
        const item_2 = document.querySelector(`[class*="${styles.switcher__item_2}"]`);
        if (!item_1 || !item_2)
            return;
        item_1.classList.remove(styles.switcher__item__checked);
        item_1.classList.add(styles.switcher__item__unchecked)
        item_2.classList.remove(styles.switcher__item__unchecked);
        item_2.classList.add(styles.switcher__item__checked)
    }

    const normalize_day_of_week = (day) => {
        if (day === 0)
            return 6;
        return (day - 1);
    };

    const month_to_string = (month) => {
        switch(month) {
            case 0:
                return 'Январь';
            case 1:
                return 'Февраль';
            case 2:
                return 'Март';
            case 3:
                return 'Апрель';
            case 4:
                return 'Май';
            case 5:
                return 'Июнь';
            case 6:
                return 'Июль';
            case 7:
                return 'Август';
            case 8:
                return 'Сентябрь';
            case 9:
                return 'Октябрь';
            case 10:
                return 'Ноябрь';
            case 11:
                return 'Декабрь';
            default:
                break;
        }
        return null;
    }

    const day_of_week_to_string = (day) => {
        switch(normalize_day_of_week(day)) {
            case 0:
                return 'Пн';
            case 1:
                return 'Вт';
            case 2:
                return 'Ср';
            case 3:
                return 'Чт';
            case 4:
                return 'Пт';
            case 5:
                return 'Сб';
            case 6:
                return 'Вс';
            default:
                break;
        }
        return null;
    }

    const generate_calendar = (year, month) => {
        let rows = [];
        let columns = [];
        const days_in_month = new Date(year, month + 1, 0).getDate();
        let date = new Date(year, month, 1);
        let day_of_week = normalize_day_of_week(date.getDay());
        if (day_of_week > 0) {
            let prev_date = date.addDays(-day_of_week)
            for(--day_of_week; day_of_week >= 0; --day_of_week, prev_date = prev_date.addDays(1)) {
                columns.push(
                    <div key={uuid()} className={styles.cell+' '+styles.cell__inactive}>
                        <p key={uuid()} className={styles.cell__day_of_week+' '+styles.cell__text__inactive}>
                            {day_of_week_to_string(prev_date.getDay())}
                        </p>
                        <p key={uuid()} className={styles.cell__day+' '+styles.cell__text__inactive}>
                            {prev_date.getDate()}
                        </p>
                    </div>
                 );
            }
        }
        while((date.getDate() <= days_in_month) && (date.getMonth() === month)) {
            day_of_week = normalize_day_of_week(date.getDay());
            while((day_of_week < 7) && (date.getDate() <= days_in_month) && (date.getMonth() === month)) {
                columns.push(
                    <div key={uuid()} className={styles.cell}>
                        <p key={uuid()} className={styles.cell__day_of_week}>
                            {day_of_week_to_string(date.getDay())}
                        </p>
                        <p key={uuid()} className={styles.cell__day}>
                            {date.getDate()}
                        </p>
                        {(0) ?
                            <div key={uuid()} className={styles.cell_profit+' '+styles.cell_profit__positive}>
                                <p key={uuid()} className={styles.cell_profit__text}>$128.15</p>
                            </div> :
                            <template/>
                        }
                    </div>
                 );
                ++day_of_week;
                date = date.addDays(1)
            }
            if (normalize_day_of_week(date.getDay()) === 0) {
                rows.push(
                    <div key={uuid()} className={styles.calendar_row}>{columns}</div>
                );
                columns = [];
            }
        }
        day_of_week = normalize_day_of_week(date.getDay());
        if (day_of_week > 0 && day_of_week <= 6) {
            let post_date = date;
            for(; day_of_week < 7; ++day_of_week, post_date = post_date.addDays(1)) {
                columns.push(
                    <div key={uuid()} className={styles.cell+' '+styles.cell__inactive}>
                        <p key={uuid()} className={styles.cell__day_of_week+' '+styles.cell__text__inactive}>
                            {day_of_week_to_string(post_date.getDay())}
                        </p>
                        <p key={uuid()} className={styles.cell__day+' '+styles.cell__text__inactive}>
                            {post_date.getDate()}
                        </p>
                    </div>
                 );
            }
        }
        if (columns) {
            rows.push(
                    <div key={uuid()} className={styles.calendar_row}>{columns}</div>
                );
        }
        return rows;
    };

    return (
        <div className={styles.block}>
            <div className={styles.chart_block}>
                <h1 className={styles.title__text}>Аналитика</h1>
                <p className={styles.chart__v_title}>Профит ($)</p>
                <div id="chart" className={styles.chart}></div>
            </div>
            <div className={styles.calendar_block}>
                <div className={styles.calendar_settings}>
                    <p className={styles.calendar__text_1}>Чистая прибыль к убытку</p>
                    <div className={styles.switcher}>
                        <div className={styles.switcher__item_1+' '+styles.switcher__item__checked}
                             onClick={(event) => on_details_click(event)}>
                            <p className={styles.switcher__text}>Детально</p>
                        </div>
                        <div className={styles.switcher__item_2+' '+styles.switcher__item__unchecked}
                             onClick={(event) => on_total_click(event)}>
                            <p className={styles.switcher__text}>Всего</p>
                        </div>
                    </div>
                </div>
                <div className={styles.calendar_stats}>
                    <div className={styles.calendar_switcher}>
                        <div className={styles.calendar_switcher_month}>
                            <img className={styles.calendar_switcher__img} src={img_arrow_left} alt='<'
                                 onClick={(event) => on_prev_month_click(event)}/>
                            <p className={styles.calendar_switcher__month_text}>{month_to_string(current_date.getMonth())}</p>
                            <img className={styles.calendar_switcher__img} src={img_arrow_right} alt='>'
                                 onClick={(event) => on_next_month_click(event)}/>
                        </div>
                        <div className={styles.calendar_switcher_year}>
                            <p className={styles.calendar_switcher__year_text}>{current_date.getFullYear()}</p>
                        </div>
                    </div>
                    <div className={styles.stats}>
                        <div className={styles.stats_item}>
                            <p className={styles.stats__item_text_header}>
                                Income
                            </p>
                            <p className={styles.stats__item_text_value + ' ' + styles.stats__item_text_positive}>
                                $87.63
                            </p>
                        </div>
                        <div className={styles.stats_item}>
                            <p className={styles.stats__item_text_header}>
                                Loss
                            </p>
                            <p className={styles.stats__item_text_value + ' ' + styles.stats__item_text_negative}>
                                -$87.63
                            </p>
                        </div>
                        <div className={styles.stats_item}>
                            <p className={styles.stats__item_text_header}>
                                Net Profit
                            </p>
                            <p className={styles.stats__item_text_value + ' ' + styles.stats__item_text_positive}>
                                $78.88
                            </p>
                        </div>
                        <div className={styles.stats_item}>
                            <p className={styles.stats__item_text_header}>
                                Deposit
                            </p>
                            <p className={styles.stats__item_text_value + ' ' + styles.stats__item_text_positive}>
                                25.47%
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.calendar}>
                    {calendar_data}
                </div>
            </div>
        </div>
    );
};

export default Analytics;
