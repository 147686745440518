let sys_canvas_text_utils = null;


export function measure_text_width(elem) {
    if (!sys_canvas_text_utils)
        sys_canvas_text_utils = document.createElement('canvas');
    if (!elem['innerText'])
        return 0;
    const context = sys_canvas_text_utils.getContext('2d');
    context.font = getComputedStyle(elem).font;
    return context.measureText(elem.innerText).width;
}
