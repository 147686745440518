export function decimal_to_string(value, precision, symbol = null, salign = 'l', remove_zeros = true) {
    if (value === null)
        return '-';
    let text = value.toFixed(precision);
    const minus = text.startsWith('-');
    if (minus)
        text = text.slice(1);
    if (remove_zeros && (text.includes('.') || text.includes(','))) {
        let i = text.length - 1;
        for(; i >= 0 && text[i] === '0'; --i) {}
        if (i >= 0 && (text[i] === '.' || text[i] === ','))
            --i;
        text = text.slice(0, i + 1);
    }
    if (symbol !== null) {
        if (salign === 'r')
            text = `${text}${symbol}`;
        else
            text = `${symbol}${text}`;
    }
    return (minus ? `-${text}` : text);
}
