import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './profile-menu.module.css';
import img_avatar from './static/images/avatar.svg';
import img_profile_logout_menu from './static/images/prfile-menu-logout.svg';
import {logout} from '../auth/common';


const ProfileMenu = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('click', on_window_click);
        return () => {
            window.removeEventListener('click', on_window_click);
        };
    }, []);

    const on_window_click = (event) => {
        if (!(event.target.className.includes(styles.menu) || event.target.closest(`[class*=${styles.menu}]`)))
            props.show_profile_menu(false);
    };

    const on_profile_click = (event) => {
        event.stopPropagation();
        props.show_profile_menu(false);
        navigate('/account/profile');
    }

    const on_logout_click = async (event) => {
        event.stopPropagation();
        await logout();
        props.show_profile_menu(false);
        navigate('/');
    };

    return (
        <div className={styles.menu}>
            <div className={styles.profile_name}
                 onClick={(event) => on_profile_click(event)}>
                <img className={styles.profile_menu__avatar} src={img_avatar} alt="Avatar"/>
                <p className={styles.profile_menu__user_name}>
                    {props.user_info && props.user_info['user_name'] ? props.user_info.user_name : null}
                </p>
            </div>
            <ul className={styles.menu_list}>
                <li className={styles.menu__item} onClick={(event) => {on_logout_click(event)}}>
                    <img className={styles.menu__icon} src={img_profile_logout_menu} alt="Logout"/>
                    Выйти
                </li>
            </ul>
        </div>
    );
};

export default ProfileMenu;
