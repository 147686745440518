import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import styles from './mytrades.module.css';
import TradingViewWidget from '../../../tradingview/tradingview';
import OrdersData from './ordersdata';
import TextArea from '../../../textarea/textarea';
import {get_request_positions_data, on_textarea_load, on_textarea_write} from './controller';


const TableRowEx = (props) => {
    const get_position_id = () => {
        if (!get_request_positions_data() || (props.index >= get_request_positions_data().positions.length))
            return null;
        return get_request_positions_data().positions[props.index].id;
    };

    const get_position = () => {
        if (!get_request_positions_data() || (props.index >= get_request_positions_data().positions.length))
            return null;
        return get_request_positions_data().positions[props.index];
    };

    const get_exchange_name = () => {
        const position = get_position();
        if (position === null || position.exchange_name === null)
            return null;
        switch(position.exchange_name) {
            case 'bybit':
                return 'BYBIT';
            case 'binance':
                return 'BINANCE';
            case 'okx':
                return 'OKX';
            case 'bitget':
                return 'BITGET';
        }
        return null;
    };

    const get_exchange_ticker = () => {
        const position = get_position();
        if (position === null)
            return null;
        switch(position.category) {
            case 'linear':
                return `${position.ticker}.P`;
            case 'spot':
                return position.ticker;
        }
        return position.ticker;
    };

    return (
        <div className={styles.ext_data}>
            <div className={styles.ext_data_chart}>
                <TradingViewWidget exchange_name={get_exchange_name()}
                                   exchange_ticker={get_exchange_ticker()}
                                   interval={'D'}/>
            </div>
            <div className={styles.ext_data_info}>
                <TextArea on_load={on_textarea_load}
                          on_write={on_textarea_write}
                          kwargs={{'get_position_id': get_position_id}}
                          placeholder={'Описание...'}
                          maxlength={'2048'}
                          auto_grow={true}
                          get_method={'getpositiondescription'}
                          write_method={'writepositiondescription'}
                          style={{height: '40px', minHeight: '60px', maxHeight: '100px'}}/>
                <div className={styles.ext_data__textarea_spacer}/>
                <TextArea on_load={on_textarea_load}
                          on_write={on_textarea_write}
                          kwargs={{'get_position_id': get_position_id}}
                          placeholder={'Вывод...'}
                          maxlength={'2048'}
                          auto_grow={true}
                          get_method={'getpositionconclusion'}
                          write_method={'writepositionconclusion'}
                          style={{height: '40px', minHeight: '60px', maxHeight: '100px'}}/>
                <div className={styles.ext_data__textarea_spacer}/>
                <TextArea on_load={on_textarea_load}
                          on_write={on_textarea_write}
                          kwargs={{'get_position_id': get_position_id}}
                          placeholder={'Ссылка на видео...'}
                          maxlength={'1024'}
                          auto_grow={true}
                          get_method={'getpositionvideourl'}
                          write_method={'writepositionvideourl'}
                          style={{height: '40px', minHeight: '40px', maxHeight: '100px'}}/>
                <OrdersData {...props} />
                <div className={styles.orders_table_legend}>
                    <span className={styles.legend__marker + ' ' + styles.order_row__marker_market}/>
                    <p className={styles.legend__text}>- Маркет ордер, </p>
                    <span className={styles.legend__marker + ' ' + styles.order_row__marker_limit}/>
                    <p className={styles.legend__text}> - Лимитный ордер / Фандинг</p>
                </div>
            </div>
        </div>
    );
};

export default TableRowEx;
