import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import axios from 'axios';
import moment from 'moment';
import {get_axios_config} from '../../../../static/js/network';
import styles from './mytrades.module.css';
import img_market_closed from './static/images/market-closed.svg';


const OpenPercents = (props) => {
    const [widget_uuid] = useState(uuid());
    const canvas_ref = useRef(null);

    useEffect(() => {
        on_draw();
    }, []);

    useLayoutEffect(() => {
        on_draw();
    }, []);

    const on_draw = () => {
        if (!props.market_data || !props.market_data.is_open)
            return;
        const canvas = canvas_ref.current;
        if (!canvas || !canvas.getContext)
            return;
        canvas.width = canvas.getBoundingClientRect().width;
        canvas.height = canvas.getBoundingClientRect().height;
        const ctx = canvas.getContext('2d');
        const radius = Math.min(canvas.width, canvas.height) / 2;
        const cx = canvas.width / 2;
        const cy = canvas.height / 2;
        const angle = (props.market_data.percents * 360) / 100;
        ctx.beginPath();
            ctx.arc(cx, cy, radius, 0, 2 * Math.PI);
            ctx.fillStyle = '#484848';
            ctx.fill();
        ctx.closePath();
        ctx.beginPath();
            ctx.moveTo(cx, cy);
            ctx.arc(cx, cy, radius, -90 * (Math.PI / 180), (-90 + angle) * (Math.PI / 180));
            ctx.lineTo(cx,cy);
            ctx.fillStyle = '#2ED4A2';
            ctx.fill();
        ctx.closePath();
    };

    const create_icon = () => {
        if (!props.market_data || !props.market_data.is_open) {
            return (
                <img key={`${widget_uuid}-1`} src={img_market_closed} alt='Icon'/>
            );
        }
        return ([
            <canvas ref={canvas_ref} key={`${widget_uuid}-2`} id={`${widget_uuid}-2`} className={styles.market_canvas}/>,
            <p key={`${widget_uuid}-3`} className={styles.market_canvas__text}>
                {`${props.market_data.percents.toFixed(0)}%`}
            </p>
        ]);
    };

    return (
        <div key={`${widget_uuid}-4`} className={styles.market_icon}>
            {create_icon()}
        </div>
    );
};


const MarketStatus = () => {
    const [markets_uuid, set_markets_uuid] = useState(uuid());
    const markets_data = useRef({});

    useEffect(() => {
        on_request_market_status();
        const interval_id = setInterval(on_request_market_status, 60000);
        return () => clearInterval(interval_id);
    }, []);

    const on_request_market_status = () => {
        axios.get(`/api/exchanges/marketstatus/`, get_axios_config('get'))
        .then((response) => {
            if (response.status === 200) {
                function to_ms(value) {
                    return ((value.hours() * 60 * 60 * 1000) + (value.minutes() * 60 * 1000) + (value.seconds() * 1000));
                }
                for(const [exchange, market_status] of Object.entries(response.data)) {
                    const is_open = market_status.is_market_open;
                    const tto = moment(market_status.time_to_open, 'HH:mm:ss');
                    const ttc = moment(market_status.time_to_close, 'HH:mm:ss');
                    const tao = moment(market_status.time_after_open, 'HH:mm:ss');
                    markets_data.current[exchange] = {
                        is_open: is_open,
                        tto: tto,
                        ttc: ttc,
                        tao: tao,
                        percents: (is_open ? ((to_ms(tao) / (to_ms(tao) + to_ms(ttc))) * 100.0) : null),
                    };
                }
                set_markets_uuid(uuid());
            }
        })
        .catch((error) => {
            console.error(error);
        });
    };

    return (
        <div className={styles.markets_status}>
            <p className={styles.markets_status__header_text}>Статусы рынков</p>
            <div key={markets_uuid} className={styles.markets}>
                <div className={styles.market}>
                    <div className={styles.market_info}>
                        <p className={styles.market_info__name}>
                            США
                        </p>
                        <p className={styles.market_info__status}>
                            {(markets_data.current['NYSE'] && markets_data.current['NYSE'].is_open) ? 'Открыт' : 'Закрыт'}
                        </p>
                    </div>
                    <OpenPercents market_data={markets_data.current['NYSE']}/>
                </div>
                <div className={styles.market}>
                    <div className={styles.market_info}>
                        <p className={styles.market_info__name}>
                            Европа
                        </p>
                        <p className={styles.market_info__status}>
                            {(markets_data.current['LSE'] && markets_data.current['LSE'].is_open) ? 'Открыт' : 'Закрыт'}
                        </p>
                    </div>
                    <OpenPercents market_data={markets_data.current['LSE']}/>
                </div>
                <div className={styles.market}>
                    <div className={styles.market_info}>
                        <p className={styles.market_info__name}>
                            Азия
                        </p>
                        <p className={styles.market_info__status}>
                            {(markets_data.current['SGX'] && markets_data.current['SGX'].is_open) ? 'Открыт' : 'Закрыт'}
                        </p>
                    </div>
                    <OpenPercents market_data={markets_data.current['SGX']}/>
                </div>
            </div>
        </div>
    );
};

export default MarketStatus;
