import React, {useEffect, useLayoutEffect, useState} from 'react';
import DOMPurify from 'dompurify';
import axios from "axios";
import $ from 'jquery';
import {useNavigate} from "react-router-dom";
import {get_axios_config} from '../../static/js/network';
import {check_is_auth, password_show_hide, clear_all_messages, set_error_message} from './common';
import {parse_html_with_css} from "../../static/js/parsers";
import styles from './auth.module.css';
import img_logo from './../../static/images/logo.svg';
import img_close from './../../static/images/close.svg';


const AuthSignIn = (props) => {
    const navigate = useNavigate();
    const [form_html, set_form_html] = useState(null);

    useLayoutEffect(() => {
        setup_sign_in();
    }, []);

    useEffect(() => {
        if (form_html) {
            const items = document.querySelectorAll(`[class*="${styles.auth_field__passwd}"]`);
            for (const item of items)
                item.addEventListener('click', password_show_hide);
            const form = document.querySelector('#auth_form__sign_in');
            if (form)
                form.addEventListener('submit', form_submit_handler);
        }
        return () => {
            if (form_html) {
                const items = document.querySelectorAll(`[class*="${styles.auth_field__passwd}"]`);
                for (const item of items)
                    item.removeEventListener('click', password_show_hide);
                const form = document.querySelector('#auth_form__sign_in');
                if (form)
                    form.removeEventListener('submit', form_submit_handler);
            }
        };
    }, [form_html]);

    const setup_sign_in = async () => {
        if (await check_is_auth())
            return navigate('/account');
        if (form_html)
            return;
        axios.get('/api/auth/signinform/', get_axios_config('get', 'json'))
            .then((response) => {
                if (response.status === 200) {
                    if (!form_html)
                        set_form_html(parse_html_with_css(DOMPurify.sanitize(response.data), styles));
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const form_submit_handler = (event) => {
        event.preventDefault();
        clear_all_messages();
        const data = $('#auth_form__sign_in').serialize();
        axios.post('/api/auth/signin/', data, get_axios_config('post', 'form'))
            .then((response) => {
                if (response.status === 200)
                    return navigate('/account');
            })
            .catch((error) => {
                if (error.status === 400)
                    return set_error_message(error.response.data.code, error.response.data.msg);
                console.error(error);
            });
    }

    const check_fields = () => {
        const form = document.querySelector('#auth_form__sign_in');
        if (!form)
            return false;
        const email = form.querySelector('#id_email');
        const password = form.querySelector('#id_password');
        return ((email && email.value) ||
                (password && password.value));
    }

    const close_click = (event = null) => {
        if (event && event.target.classList.contains(styles.auth_background) && !check_fields())
            return props.show_auth_sign_in(false);
        if (event && event.target.closest(`[class*="${styles.auth__close}"]`))
            return props.show_auth_sign_in(false);
    }

    const sign_up_click = (event) => {
        props.show_auth_sign_in(false);
        props.show_auth_sign_up(true);
    }

    return (
        <div className={styles.auth_background} onClick={(event) => { close_click(event) }}>
            <div className={styles.auth_block}>
                <div className={styles.auth__close} onClick={(event) => { close_click(event) }}>
                    <img src={img_close} alt="Close"/>
                </div>
                <div className={styles.auth_logo}>
                    <img className={styles.auth_logo__icon} src={img_logo} alt="Logo"/>
                    <p className={styles.auth_logo__text}>Trader<br/>diary</p>
                </div>
                <p className={styles.auth__title}>Войти в аккаунт</p>
                {form_html}
                <div className={styles.auth_privacy_policy_margin}></div>
                <p className={styles.auth__text}>
                    <span className={styles.auth__link} onClick={(event) => { sign_up_click(event); }}>
                        Регистрация
                    </span>
                    , если у вас нет аккаунта
                </p>
                <div className={styles.auth__bottom}></div>
            </div>
        </div>
    );
}

export default AuthSignIn;
