import DOMPurify from 'dompurify';
import axios from 'axios';
import styles from './auth.module.css';
import {get_axios_config} from '../../static/js/network';


export async function check_is_auth() {
    return axios.get('/api/auth/isauth/', get_axios_config('get', 'plain'))
            .then((response) => {
                if (response.status === 200)
                    return response.data.is_auth;
                return false;
            })
            .catch((error) => {
                console.error(error);
                return false;
            })
}

export async function logout() {
    return axios.get('/api/auth/logout/', get_axios_config('get', 'plain'))
            .then((response) => {
                if (response.status === 200)
                    return true;
                console.log(response.data.msg);
            })
            .catch((error) => {
                console.error(error);
                return false;
            })
}

export function password_show_hide(event) {
    const input = event.target
        .closest(`[class*="${styles.auth_field}"`)
        .querySelector(`[class*="${styles.auth_field__input}"`);
    if (!input)
        return;
    event.target.classList.remove(styles.auth_field__passwd__show);
    event.target.classList.remove(styles.auth_field__passwd__hide);
    if (input.type === "password") {
        input.type = "text";
        event.target.classList.add(styles.auth_field__passwd__show);
    } else {
        input.type = "password";
        event.target.classList.add(styles.auth_field__passwd__hide);
    }
}

export function clear_all_messages() {
    let items = document.querySelectorAll(`[class*="${styles.auth_field__error_text}"`);
    for(const item of items) {
        item.innerHTML = '';
        if (!item.classList.contains(styles.auth_field__error_text__hidden))
            item.classList.add(styles.auth_field__error_text__hidden);
    }
    items = document.querySelectorAll(`[class*="${styles.auth_field__success_text}"`);
    for(const item of items) {
        item.innerHTML = '';
        if (!item.classList.contains(styles.auth_field__success_text__hidden))
            item.classList.add(styles.auth_field__success_text__hidden);
    }
}

export function set_error_message(code, msg) {
    var error_text = null
    switch (code) {
        case 0:
            error_text = document.querySelector(`[class*="${styles.auth_type__form}"`);
            break;
        case 1:
        case 6:
            error_text = document.querySelector(`[class*="${styles.auth_type__user_name}"`)
                                 .closest(`[class*="${styles.auth_field_wrapper}"`)
                                 .querySelector(`[class*="${styles.auth_field__error_text}"`);
            break;
        case 2:
        case 4:
            error_text = document.querySelector(`[class*="${styles.auth_type__email}"`)
                                 .closest(`[class*="${styles.auth_field_wrapper}"`)
                                 .querySelector(`[class*="${styles.auth_field__error_text}"`);
            break;
        case 3:
            error_text = document.querySelector(`[class*="${styles.auth_type__passwd_2}"`)
                                 .closest(`[class*="${styles.auth_field_wrapper}"`)
                                 .querySelector(`[class*="${styles.auth_field__error_text}"`);
            break;
        case 5:
            error_text = document.querySelector(`[class*="${styles.auth_type__passwd_1}"`)
                                 .closest(`[class*="${styles.auth_field_wrapper}"`)
                                 .querySelector(`[class*="${styles.auth_field__error_text}"`);
            break;
        case 7:
            error_text = document.querySelector(`[class*="${styles.auth_type__passwd_old}"`)
                                 .closest(`[class*="${styles.auth_field_wrapper}"`)
                                 .querySelector(`[class*="${styles.auth_field__error_text}"`);
            break;
        default:
            break;
    }
    if (!error_text)
        return;
    error_text.innerHTML = DOMPurify.sanitize(msg);
    if (error_text.classList.contains(styles.auth_field__error_text__hidden))
        error_text.classList.remove(styles.auth_field__error_text__hidden);
}

export function set_success_message(msg) {
    const success_text = document.querySelector(`[class*="${styles.auth_field__success_text}"`);
    if (!success_text)
        return;
    success_text.innerHTML = DOMPurify.sanitize(msg);
    if (success_text.classList.contains(styles.auth_field__success_text__hidden))
        success_text.classList.remove(styles.auth_field__success_text__hidden);
}
