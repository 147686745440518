import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import axios from "axios";
import {get_axios_config} from "../../../../static/js/network";
import {parse_html_with_css} from "../../../../static/js/parsers";
import DOMPurify from "dompurify";
import ApiKeyDialog from "./apikeydialog";
import styles from './apikeys.module.css';
import img_key from './static/images/key.svg';
import img_key_settings from './static/images/key-settings.svg';
import img_bybit_logo from '../../../../static/images/bybit-logo.svg';
import img_bitget_logo from '../../../../static/images/bitget-logo.svg';
import img_whitelist_logo from '../../../../static/images/whitelist-logo.svg';
import img_binance_logo from '../../../../static/images/binance-logo.svg';
import img_tigertrade_logo from '../../../../static/images/tigertrade-logo.svg';


const ApiKeys = () => {
    const navigate = useNavigate();
    const [api_key_dialog_settings, set_api_key_dialog_settings] = useState({
        edit_mode: false
    });
    const [show_api_key_dialog, set_show_api_key_dialog] = useState(false);
    const [api_keys_data, set_api_keys_data] = useState(null)

    useLayoutEffect(() => {
        if (!api_keys_data)
            setup_api_keys_data();
    }, []);

    useEffect(() => {
        setup_api_keys_data();
    }, [show_api_key_dialog]);

    const setup_api_keys_data = async () => {
        axios.get('/api/exchanges/getallapikeys/', get_axios_config('get', 'json'))
            .then((response) => {
                if (response.status === 200) {
                    response.data.keys.sort((a, b) => {
                        if (a.timestamp_ms < b.timestamp_ms)
                            return -1;
                        else if (a.timestamp_ms === b.timestamp_ms)
                            return 0;
                        else
                            return 1;
                    });
                    let keys = [];
                    for(const key of response.data.keys) {
                        keys.push(
                            <div key={uuid()} id={key.id} className={styles.key}>
                                <div key={uuid()} className={styles.key_section_1}>
                                    <p key={uuid()} className={styles.key__name}>
                                        {key.account_name}
                                    </p>
                                    <p key={uuid()} className={styles.key__uid}>
                                        {`#${key.id}`}
                                    </p>
                                    <img key={uuid()} className={styles.key__exchange_logo}
                                         src={get_logo_by_exchange_name(key.exchange_name)} alt="Exchange"/>
                                </div>
                                <div key={uuid()} className={styles.key_section_2}>
                                    <p key={uuid()} className={styles.key__exchange_key}>
                                        {get_short_public_api_key(key.public_api_key)}
                                    </p>
                                    <p key={uuid()} className={styles.key__connection_status}>
                                        {key.status}
                                    </p>
                                    <p key={uuid()} className={styles.key__info_text}>
                                        Трейды будут автоматически добавлены<br/>в дневник
                                    </p>
                                </div>
                                <div key={uuid()} className={styles.key_section_3}>
                                    <p key={uuid()} className={styles.key__reconnect}>
                                        Переподключить
                                    </p>
                                    <p key={uuid()} className={styles.key__disconnect}
                                       onClick={(event) => {on_disconnect_key(event)}}>
                                        Отключить
                                    </p>
                                    <img key={uuid()} className={styles.key__settings_img} src={img_key_settings} alt="Settings"
                                         onClick={(event) => on_edit_api_key(event)}/>
                                </div>
                            </div>
                        );
                    }
                    set_api_keys_data(keys);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const get_short_public_api_key = (key) => {
        const s = Math.trunc(key.length / 3);
        const lhs = key.slice(0, Math.max(0, Math.trunc(key.length / 2 - s / 2)));
        const rhs = key.slice(Math.min(Math.trunc(key.length / 2 + s / 2), key.length), key.length);
        return `${lhs}${'*'.repeat(s)}${rhs}`;
    };

    const get_logo_by_exchange_name = (name) => {
        switch(name) {
            case 'bybit':
                return img_bybit_logo;
            case 'bitget':
                return img_bitget_logo;
            case 'whitelist':
                return img_whitelist_logo;
            case 'binance':
                return img_binance_logo;
            case 'tigertrade':
                return img_tigertrade_logo;
            default:
                break;
        }
        return null;
    }

    const on_create_new_api_key = (event) => {
        event.stopPropagation();
        set_api_key_dialog_settings({
            ...api_key_dialog_settings,
            edit_mode: false,
            exchange_name: 'bybit',
            category: 'linear'
        });
        set_show_api_key_dialog(true);
    };

    const on_edit_api_key = (event) => {
        event.stopPropagation();
        const key = event.target.closest(`[class*="${styles.key}"]`);
        if (!key)
            return;
        set_api_key_dialog_settings({
            ...api_key_dialog_settings,
            edit_mode: true,
            key_id: key.id
        });
        set_show_api_key_dialog(true);
    }

    const on_disconnect_key = (event) => {
        const key = event.target.closest(`[class*="${styles.key}"]`);
        if (!key)
            return;
        axios.get(`/api/exchanges/removeapikey/${key.id}`, get_axios_config('get', 'json'))
            .then((response) => {
                if (response.status === 200)
                    setup_api_keys_data();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const get_new_api_key_block = () => {
        return (
            <div key={uuid()} className={styles.key + ' ' + styles.key_new}
                 onClick={(event) => on_create_new_api_key(event)}>
                <div key={uuid()} className={styles.new_api_key_block}>
                    <p key={uuid()} className={styles.new_api_key_text + ' ' + styles.new_api_key_text__large}>+</p>
                    <p key={uuid()} className={styles.new_api_key_text}>
                        Добавить API ключ
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.block}>
            {show_api_key_dialog ? <ApiKeyDialog {...api_key_dialog_settings} set_show_api_key_dialog={set_show_api_key_dialog}/> : <template/>}
            <div className={styles.header}>
                <img className={styles.header__img} src={img_key} alt="Key"/>
                <p className={styles.header__title}>API ключи</p>
                <p className={styles.header__text}>Добавление или удаление<br/>API ключей</p>
            </div>
            <div className={styles.content}>
                <div className={styles.scrollbar_top_shadow}/>
                <div className={styles.keys__scrollbar}>
                    {api_keys_data}
                    {get_new_api_key_block()}
                </div>
                <div className={styles.scrollbar_bottom_shadow}/>
            </div>
        </div>
    );
};

export default ApiKeys;
