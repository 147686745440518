import React, { useEffect, useRef, memo } from 'react';
import styles from './tradingview.module.css';


const TradingViewWidget = (props) => {
    const container = useRef();

    useEffect(() => {
        if (container.current.children.length)
            return;
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
        {
            "autosize": true,
            "symbol": "${props.exchange_name}:${props.exchange_ticker}",
            "interval": "${props.interval}",
            "timezone": "Etc/UTC",
            "theme": "dark",
            "style": "1",
            "locale": "ru",
            "withdateranges": true,
            "hide_side_toolbar": false,
            "allow_symbol_change": false,
            "calendar": true,
            "support_host": "https://www.tradingview.com"
        }`;
        container.current.appendChild(script);
    }, []);

    return (
        <div ref={container} className={'tradingview-widget-container '+styles.tradingview_widget_container}/>
    );
}

export default memo(TradingViewWidget);
