import parse from "html-react-parser";


export function parse_html_with_css(html_src, css_styles) {
    const replace_classes = (classes) => {
        const src_class_list = classes.split(' ');
        let dst_class_list = [];
        for(let class_name of src_class_list) {
            if (class_name && css_styles[class_name])
                dst_class_list.push(css_styles[class_name]);
        }
        return dst_class_list.join(' ');
    }
    const options = {
        replace(dom_node) {
            if (!dom_node.attribs || !dom_node.attribs['class'])
                return dom_node;
            dom_node.attribs.class = replace_classes(dom_node.attribs.class);
            return dom_node;
        },
    };
    return parse(html_src, options);
}
