import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {decimal_to_string} from '../../../../static/js/numutils';
import moment from 'moment';
import styles from './mytrades.module.css';
import img_checkbox_checkmark from './static/images/checkbox-checkmark.svg';
import {get_request_positions_data} from './controller';
import {add_handler, remove_handler} from '../../../../static/js/handlers';
import {set_row_checkbox_checked, get_row_checkbox_checked, on_textarea_load, on_textarea_write} from './controller';
import TextArea from "../../../textarea/textarea";


const RowData = (props) => {
    const [position_data, set_position_data] = useState({
        id: null,
        timestamp_ms: null,
        exchange_name: null,
        category: null,
        api_key: null,
        closed: null,
        ticker: null,
        side: null,
        entry_timestamp_ms: null,
        exit_timestamp_ms: null,
        duration_ms: null,
        leverage: null,
        avg_price_entry: null,
        avg_price_exit: null,
        profit_percents: null,
        funding_value: null,
        pnl_value: null,
        net_profit_value: null,
        net_deposit_value: null,
        qty: null,
        peak_qty: null,
        volume_value: null,
        fee_value: null,
        entry_reason: null,
        has_description: false,
        has_conclusion: false,
    });
    const [row_uuid, set_row_uuid] = useState(uuid());
    const [row_checked, set_row_checked] = useState(get_row_checkbox_checked(props.index));

    useLayoutEffect(() => {
        props.set_rows_uuid(uuid());
    }, [position_data, row_checked]);

    useEffect(() => {
        add_handler('my_trades', 'on_positions_updated', on_positions_updated);
        add_handler('my_trades_row_data', 'on_row_checkbox_changed', on_row_checkbox_changed);
        on_positions_updated(null);
        return () => {
            remove_handler('my_trades', 'on_positions_updated', on_positions_updated);
            remove_handler('my_trades_row_data', 'on_row_checkbox_changed', on_row_checkbox_changed);
        };
    }, []);

    const on_positions_updated = (p) => {
        if (!get_request_positions_data() || (props.index >= get_request_positions_data().positions.length))
            return;
        set_position_data(get_request_positions_data().positions[props.index]);
        set_row_uuid(uuid());
    };

    const on_row_checkbox_changed = (p) => {
        set_row_checked(get_row_checkbox_checked(props.index));
    };

    const on_row_ticker_checkbox_checked = (event) => {
        set_row_checkbox_checked(props.index, event.target.checked);
        set_row_checked(get_row_checkbox_checked(props.index));
    };

    const get_position_id = () => {
        if (!get_request_positions_data() || (props.index >= get_request_positions_data().positions.length))
            return null;
        return get_request_positions_data().positions[props.index].id;
    };

    const exchange_name_to_hrt = (name) => {
        if (name === null)
            return '-';
        switch(name) {
            case 'bybit':
                return 'Bybit';
            case 'binance':
                return 'Binance';
            case 'okx':
                return 'OKX';
            case 'bitget':
                return 'Bitget';
        }
        return name;
    };

    const category_to_hrt = (name) => {
        if (name === null)
            return '-';
        switch(name) {
            case 'linear':
                return 'Фьючерсы';
            case 'spot':
                return 'Спот';
        }
        return name;
    };

    const ticker_to_hrt = (name) => {
        if (name === null)
            return '-';
        if (name.endsWith('USDT'))
            return `${name.slice(0, -4)}/USDT`;
        if (name.endsWith('USDC'))
            return `${name.slice(0, -4)}/USDC`;
        return name
    };

    const side_to_hrt = (name) => {
        if (name === null)
            return '-';
        switch (name) {
            case 'buy':
                return 'LONG';
            case 'sell':
                return 'SHORT';
        }
        return name
    };

    const timestamp_to_string = (timestamp_ms) => {
        if (timestamp_ms === null)
            return '-';
        const date = moment.utc(timestamp_ms).local();
        return (<span>{date.format('DD.MM.YYYY')}<br/>{date.format('HH:mm:ss')}</span>);
    };

    const duration_to_string = (entry_time_ms, duration_ms) => {
        if (duration_ms === null)
            duration_ms = (moment.utc().valueOf() - entry_time_ms);
        let parts = [];
        const duration = moment.duration(duration_ms);
        if (duration.years() >= 1)
            parts.push(`${Math.floor(duration.years())}г`);
        if (duration.months() >= 1)
            parts.push(`${Math.floor(duration.months())}мс`);
        if (duration.days() >= 1)
            parts.push(`${Math.floor(duration.days())}д`);
        if (duration.hours() >= 1)
            parts.push(`${Math.floor(duration.hours())}ч`);
        if (duration.minutes() >= 1)
            parts.push(`${Math.floor(duration.minutes())}м`);
        if (!parts.length && duration.seconds() >= 1)
            parts.push(`${Math.floor(duration.seconds())}c`);
        if (!parts.length)
            return '-';
        return parts.join(' ');
    };

    return (
        <div key={row_uuid} className={styles.table_row_data}>
            <div className={styles.table_column__ticker_checkbox}>
                <div className={styles.table_column__ticker_block}>
                    <label className={styles.checkbox_block}>
                        <input id={uuid()} className={styles.checkbox__input} type="checkbox" defaultChecked={row_checked}
                               onClick={(event) => on_row_ticker_checkbox_checked(event)}/>
                        <img className={styles.checkbox__checkmark} src={img_checkbox_checkmark} alt='Checkmark'/>
                    </label>
                </div>
            </div>
            <div className={styles.table_column__ticker}>
                <p className={styles.table_row_item__text}>
                    {ticker_to_hrt(position_data.ticker)}
                </p>
            </div>
            <div className={styles.table_column__exchange_name}>
                <p className={styles.table_row_item__text}>
                    {exchange_name_to_hrt(position_data.exchange_name)}
                </p>
            </div>
            <div className={styles.table_column__category}>
                <p className={styles.table_row_item__text}>
                    {category_to_hrt(position_data.category)}
                </p>
            </div>
            <div className={styles.table_column__api_key}>
                <p className={styles.table_row_item__text}>
                    {position_data.api_key}
                </p>
            </div>
            <div className={styles.table_column__entry_reason}>
                <TextArea on_load={on_textarea_load}
                          on_write={on_textarea_write}
                          kwargs={{'get_position_id': get_position_id}}
                          text={position_data.entry_reason}
                          placeholder={'-'}
                          maxlength={'128'}
                          get_method={'getpositionentryreason'}
                          write_method={'writepositionentryreason'}
                          style={{
                              height: '30px',
                              minHeight: '30px',
                              maxHeight: '30px',
                              border: 'none',
                              margin: '0 10px 0 0',
                              padding: '5px',
                              fontSize: '12px',
                          }}/>
            </div>
            <div className={styles.table_column__extra_info}>
                <div className={styles.table_row_item__extra_info_block}>
                    <div className={styles.table_row_item__extra_info_line}>
                        <label className={styles.checkbox_block}>
                            <input id={uuid()} className={styles.checkbox__input} type="checkbox"
                                   defaultChecked={position_data.has_description}/>
                            <img className={styles.checkbox__checkmark} src={img_checkbox_checkmark} alt='Checkmark'/>
                        </label>
                        <p className={styles.table_row_item__extra_info_text}>
                            Описание
                        </p>
                    </div>
                    <div className={styles.table_row_item__extra_info_line}>
                        <label className={styles.checkbox_block}>
                            <input id={uuid()} className={styles.checkbox__input} type="checkbox"
                                   defaultChecked={position_data.has_conclusion}/>
                            <img className={styles.checkbox__checkmark} src={img_checkbox_checkmark} alt='Checkmark'/>
                        </label>
                        <p className={styles.table_row_item__extra_info_text}>
                            Вывод
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.table_column__leverage}>
                <p className={styles.table_row_item__text}>
                    {decimal_to_string(position_data.leverage, 2)}
                </p>
            </div>
            <div className={styles.table_column__entry_time}>
                <p className={styles.table_row_item__text}>
                    {timestamp_to_string(position_data.entry_timestamp_ms)}
                </p>
            </div>
            <div className={styles.table_column__avg_entry_price}>
                <p className={styles.table_row_item__text}>
                    {decimal_to_string(position_data.avg_price_entry, 8, '$')}
                </p>
            </div>
            <div className={styles.table_column__exit_time}>
                <p className={styles.table_row_item__text}>
                    {timestamp_to_string(position_data.exit_timestamp_ms)}
                </p>
            </div>
            <div className={styles.table_column__avg_exit_price}>
                <p className={styles.table_row_item__text}>
                    {decimal_to_string(position_data.avg_price_exit, 8, '$')}
                </p>
            </div>
            <div className={styles.table_column__duration}>
                <p className={styles.table_row_item__text}>
                    {duration_to_string(position_data.entry_timestamp_ms, position_data.duration_ms)}
                </p>
            </div>
            <div className={styles.table_column__side}>
                <p className={styles.table_row_item__text}>
                    {side_to_hrt(position_data.side)}
                </p>
            </div>
            <div className={styles.table_column__percent}>
                <p className={styles.table_row_item__text}>
                    {decimal_to_string(position_data.profit_percents, 2, '%', 'r')}
                </p>
            </div>
            <div className={styles.table_column__pnl}>
                <p className={styles.table_row_item__text}>
                    {decimal_to_string(position_data.pnl_value, 2, '$')}
                </p>
            </div>
            <div className={styles.table_column__net_profit}>
                <p className={styles.table_row_item__text}>
                    {decimal_to_string(position_data.net_profit_value, 2, '$')}
                </p>
            </div>
            <div className={styles.table_column__qty}>
                <p className={styles.table_row_item__text}>
                    {decimal_to_string(position_data.qty, 8)}
                </p>
            </div>
            <div className={styles.table_column__peak_qty}>
                <p className={styles.table_row_item__text}>
                    {decimal_to_string(position_data.peak_qty, 8)}
                </p>
            </div>
            <div className={styles.table_column__volume}>
                <p className={styles.table_row_item__text}>
                    {decimal_to_string(position_data.volume_value, 2, '$')}
                </p>
            </div>
            <div className={styles.table_column__commission}>
                <p className={styles.table_row_item__text}>
                    {decimal_to_string(position_data.fee_value, 2, '$')}
                </p>
            </div>
            <div className={styles.table_column__funding}>
                <p className={styles.table_row_item__text}>
                    {decimal_to_string(position_data.funding_value, 2, '$')}
                </p>
            </div>
        </div>
    );
};

export default RowData;
