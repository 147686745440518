import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {has_selections, remove_all_selections} from '../../../../static/js/selection';
import RowData from './rowdata';
import TableRowEx from './tablerowex';
import {add_handler, remove_handler, send_to_handlers} from '../../../../static/js/handlers';
import {set_expanded_row, get_expanded_row} from './controller';
import styles from './mytrades.module.css';


const TableRow = (props) => {
    const [expanded, set_expanded] = useState(get_expanded_row(props.index));

    useEffect(() => {
        add_handler('my_trades_table_row', 'on_toggle_expand_row', on_toggle_expand_row);
        return () => {
            remove_handler('my_trades_table_row', 'on_toggle_expand_row', on_toggle_expand_row);
        };
    }, []);

    const on_toggle_expand_row = (p) => {
        if (props.index === p.index) {
            set_expanded_row(props.index, !get_expanded_row(props.index));
            return set_expanded(get_expanded_row(props.index));
        }
        set_expanded_row(props.index, false);
        set_expanded(get_expanded_row(props.index));
    };

    const on_table_row_click = (event) => {
        if (event.target.closest(`[class*="${styles.checkbox_block}"]`) ||
            event.target.closest(`[class*="${styles.table_column__entry_reason}"]`) ||
            event.target.closest(`[class*="${styles.table_row_ext_data}"]`) ||
            event.target.closest(`[class*="${styles.table_row_item__extra_info_line}"]`) ||
            has_selections())
            return;
        remove_all_selections();
        send_to_handlers('my_trades_table_row', 'on_toggle_expand_row', {
            index: props.index,
        });
    };

    return (
        <div id={props.index} className={styles.table_row + (expanded ? ` ${styles.table_row__expanded}` : '')}
             onClick={(event) => on_table_row_click(event)}>
            <RowData {...props}/>
            <div className={styles.table_row_ext_data + (expanded ? ` ${styles.table_row_ext_data__expanded}` : '')}>
                {expanded ? <TableRowEx {...props}/> : <template/>}
            </div>
        </div>
    );
};

export default TableRow;
