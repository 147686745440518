import React, {useEffect, useLayoutEffect, useState} from 'react';
import DOMPurify from 'dompurify';
import axios from "axios";
import $ from 'jquery';
import {get_axios_config} from '../../static/js/network';
import {password_show_hide, clear_all_messages, set_error_message, set_success_message, check_is_auth} from './common';
import {parse_html_with_css} from "../../static/js/parsers";
import {sleep} from '../../static/js/sleep';
import styles from './auth.module.css';
import {useNavigate} from "react-router-dom";


const AuthChangePassword = (props) => {
    const navigate = useNavigate()
    const [form_html, set_form_html] = useState(null);

    useLayoutEffect(() => {
        setup_ch_pwd();
    }, []);

    useEffect(() => {
        if (form_html) {
            const items = document.querySelectorAll(`[class*="${styles.auth_field__passwd}"]`);
            for (const item of items)
                item.addEventListener('click', password_show_hide);
            const form = document.querySelector('#auth_form__ch_pwd');
            if (form)
                form.addEventListener('submit', form_submit_handler);
        }
        return () => {
            if (form_html) {
                const items = document.querySelectorAll(`[class*="${styles.auth_field__passwd}"]`);
                for (const item of items)
                    item.removeEventListener('click', password_show_hide);
                const form = document.querySelector('#auth_form__ch_pwd');
                if (form)
                    form.removeEventListener('submit', form_submit_handler);
            }
        };
    }, [form_html]);

    const setup_ch_pwd = async () => {
        if (form_html)
            return;
        axios.get('/api/auth/chpwdform/', get_axios_config('get', 'html'))
            .then((response) => {
                if (response.status === 200) {
                    if (!form_html)
                        set_form_html(parse_html_with_css(DOMPurify.sanitize(response.data), styles));
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const form_submit_handler = (event) => {
        event.preventDefault();
        clear_all_messages();
        const data = $('#auth_form__ch_pwd').serialize();
        axios.post('/api/auth/chpwd/', data, get_axios_config('post', 'form'))
            .then(async (response) => {
                if (response.status === 200) {
                    set_success_message(response.data.msg);
                    await sleep(1618);
                    return navigate('/');
                }
            })
            .catch((error) => {
                if (error.status === 400)
                    return set_error_message(error.response.data.code, error.response.data.msg);
                console.error(error);
            });
    }

    return (
        <div className={styles.change_password_block}>
            {form_html}
        </div>
    );
}

export default AuthChangePassword;
