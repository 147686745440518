import React, {useEffect, useRef} from 'react';

export function useHorizontalScroll() {
    const elem_ref = useRef();
    useEffect(() => {
        const elem = elem_ref.current;
        if (elem) {
            const on_wheel_event = (event) => {
                if (event.deltaY === 0)
                    return;
                if (elem.scrollLeft === 0 && event.deltaY >= 0)
                    event.preventDefault();
                if (elem.scrollLeft === elem.scrollWidth - elem.clientWidth && event.deltaY < 0)
                    event.preventDefault();
                if (elem.scrollLeft > 0 && elem.scrollLeft < elem.scrollWidth - elem.clientWidth)
                    event.preventDefault();
                elem.scrollTo({
                    left: elem.scrollLeft + event.deltaY * 5,
                    behavior: "smooth"
                });
            };
            elem.addEventListener("wheel", on_wheel_event);
            return () => elem.removeEventListener("wheel", on_wheel_event);
        }
    }, []);
    return elem_ref;
}
