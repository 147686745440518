import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import styles from './diary-day.module.css';
import weeks_styles from "./diary-weeks.module.css";
import TradingViewWidget from "../../../tradingview/tradingview";
import img_arrow_left from "../../../../static/images/arrow-1-left-white.svg";


const DiaryDay = (props) => {
    const navigate = useNavigate();

    const on_back_button_click = (event) => {
        navigate('/account/diary/week');
    };

    const on_ticker_click = (event) => {
        const tickers = document.querySelectorAll(`[class*="${styles.ticker}"]`);
        for(const ticker of tickers) {
            ticker.classList.remove(styles.ticker__checked);
        }
        const ticker = event.target.closest(`[class*="${styles.ticker}"]`)
        if (ticker)
            ticker.classList.add(styles.ticker__checked);
    };

    const create_tickers = () => {
        let tickers = [];
        for(let i = 0; i < 20; ++i) {
            tickers.push(
                <div key={uuid()} className={styles.ticker}
                     onClick={(event) => on_ticker_click(event)}>
                    <p key={uuid()} className={styles.ticker__name}>ETHUSDT</p>
                    <div key={uuid()} className={styles.ticker_field}>
                        <p key={uuid()} className={styles.ticker_filed__name}>Net profit</p>
                        <p key={uuid()} className={styles.ticker_filed__value}>$40.603</p>
                    </div>
                    <div key={uuid()} className={styles.ticker_field}>
                        <p key={uuid()} className={styles.ticker_filed__name}>Win rate</p>
                        <p key={uuid()} className={styles.ticker_filed__value}>50%</p>
                    </div>
                    <div key={uuid()} className={styles.ticker_field}>
                        <p key={uuid()} className={styles.ticker_filed__name}>Avg leverage</p>
                        <p key={uuid()} className={styles.ticker_filed__value}>x0.38</p>
                    </div>
                    <div key={uuid()} className={styles.ticker_field}>
                        <p key={uuid()} className={styles.ticker_filed__name}>Commission</p>
                        <p key={uuid()} className={styles.ticker_filed__value}>$4.69</p>
                    </div>
                </div>
            );
        }
        return tickers;
    };

    const create_orders = () => {
        let orders = [];
        for(let i = 0; i < 40; ++i) {
            let rs = '';
            if (!(i % 3))
                rs += ` ${styles.order_row__positive}`;
            if (!(i % 5))
                rs += ` ${styles.order_row__negative}`;
            orders.push(
                <tr key={uuid()} className={styles.order_row+rs}>
                    <td key={uuid()}>SHORT</td>
                    <td key={uuid()}><div key={uuid()} className={styles.orders__ver_line}/></td>
                    <td key={uuid()}>$123.45</td>
                    <td key={uuid()}><div key={uuid()} className={styles.orders__ver_line}/></td>
                    <td key={uuid()}>45.87%</td>
                    <td key={uuid()}><div key={uuid()} className={styles.orders__ver_line}/></td>
                    <td key={uuid()}>$256.23</td>
                </tr>
            );
        }
        return orders;
    };

    return (
        <div className={styles.block_day}>
            <div className={weeks_styles.navigator + ' ' + styles.navigator}>
                <button id={uuid()} className={weeks_styles.back_button}
                        onClick={(event) => on_back_button_click(event)}>
                    <img className={weeks_styles.back_button__img} src={img_arrow_left} alt='Back'/>
                </button>
                <p className={weeks_styles.weeks__title}>12 Января 2024</p>
            </div>
            <div className={styles.day}>
                <div className={styles.scrollbar_top_shadow}/>
                <div className={styles.day__scrollbar}>
                    <div className={styles.day_info}>
                        <div className={styles.bay_block}>
                            <div className={styles.day_header}>
                                <p className={styles.day_header__text}>
                                    Итоги дня
                                </p>
                            </div>
                            <div className={styles.day_content}>
                                <div className={styles.day_stats}>
                                    <div className={styles.day_stat}>
                                        <p className={styles.day_stat__value}>
                                            50%
                                        </p>
                                        <p className={styles.day_stat__name}>
                                            Win rate<br/>
                                            Win 4/ 8
                                        </p>
                                    </div>
                                    <div className={styles.day_stat}>
                                        <p className={styles.day_stat__value + ' ' + styles.stat__positive}>
                                            $10.13
                                        </p>
                                        <p className={styles.day_stat__name}>
                                            Net profit<br/>
                                            Volume $12,928.68
                                        </p>
                                    </div>
                                    <div className={styles.day_stat}>
                                        <p className={styles.day_stat__value}>
                                            2.22
                                        </p>
                                        <p className={styles.day_stat__name}>
                                            Payoff ratio<br/>
                                            $4.62 / $2.08
                                        </p>
                                    </div>
                                    <div className={styles.day_stat}>
                                        <p className={styles.day_stat__value}>
                                            37.5%
                                        </p>
                                        <p className={styles.day_stat__name}>
                                            Long trades (3)<br/>
                                            Short 62.5% (5)
                                        </p>
                                    </div>
                                    <div className={styles.day_stat}>
                                        <p className={styles.day_stat__value + ' ' + styles.stat__negative}>
                                            -12$.8
                                        </p>
                                        <p className={styles.day_stat__name}>
                                            Commission<br/>
                                            Average $1.60
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.day_comments}>
                                    <textarea key={uuid()} id={uuid()} className={styles.comments_textarea}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={styles.hor_separator}/>
                    <div className={styles.trades_block}>
                        <div className={styles.tickers_table}>
                            <div className={styles.tickers_table_content}>
                                <div className={styles.scrollbar_top_shadow}/>
                                <div className={styles.tickers__scrollbar}>
                                    {create_tickers()}
                                </div>
                                <div className={styles.scrollbar_bottom_shadow}/>
                            </div>
                        </div>
                        <div className={styles.chart}>
                            <TradingViewWidget/>
                        </div>
                        <div className={styles.orders_table}>
                            <div className={styles.orders_table_content}>
                                <div className={styles.scrollbar_top_shadow}/>
                                <div className={styles.orders__scrollbar}>
                                    <table className={styles.orders}>
                                        <thead className={styles.orders_table_header}>
                                        <tr>
                                            <th>Side</th>
                                            <th>
                                                <div className={styles.orders__ver_line}/>
                                            </th>
                                            <th>$</th>
                                            <th>
                                                <div className={styles.orders__ver_line}/>
                                            </th>
                                            <th>%</th>
                                            <th>
                                                <div className={styles.orders__ver_line}/>
                                            </th>
                                            <th>Sum</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {create_orders()}
                                        </tbody>
                                    </table>
                                </div>
                                <div className={styles.scrollbar_bottom_shadow}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.scrollbar_bottom_shadow}/>
            </div>
        </div>
    );
};

export default DiaryDay;
