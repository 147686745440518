import React, {useLayoutEffect, useState} from 'react';
import {Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import styles from './profile.module.css';
import img_avatar from '../../static/images/avatar.svg';
import {v4 as uuid} from "uuid";
import axios from 'axios';
import {get_axios_config} from '../../../../static/js/network';
import AuthChangePassword from "../../../auth/auth-ch-pwd";


const Profile = (props) => {
    const navigate = useNavigate();

    const on_save_click = (event) => {
    };

    const on_cancel_click = (event) => {
    };

    return (
        <div className={styles.block}>
            <div className={styles.header}>
                <img className={styles.avatar_img} src={img_avatar} alt="Avatar"/>
                <p className={styles.header__title}>Профиль</p>
                <p className={styles.header__text}>
                    Ваша персональная информация<br/>и настройки
                </p>
            </div>
            <div className={styles.content}>
                <div className={styles.user_info}>
                    <div className={styles.user_info_fields}>
                        <p className={styles.field__user_name__title}>
                            Имя пользователя
                        </p>
                        <p className={styles.field__user_name__value}>
                            {props.user_info && props.user_info['user_name'] ? props.user_info.user_name : null}
                        </p>
                        <p className={styles.field__user_name__title}>
                            E-mail
                        </p>
                        <p className={styles.field__user_name__value}>
                            {props.user_info && props.user_info['email'] ? props.user_info.email : null}
                        </p>
                    </div>
                    <div className={styles.user_info_avatar}>
                        <p className={styles.avatar_picture_title}>
                            Фото профиля
                        </p>
                        <div className={styles.avatar_picture_upload}>
                            <p className={styles.avatar_picture_upload__text}>
                                Загрузить изображение<br/>PNG, JPG не более 3Мб
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button id={uuid()} className={styles.button + ' ' + styles.button__save}
                            onClick={(event) => on_save_click(event)}>
                        Сохранить
                    </button>
                    <button id={uuid()} className={styles.button + ' ' + styles.button__cancel}
                            onClick={(event) => on_cancel_click(event)}>
                        Отмена
                    </button>
                </div>
                <div className={styles.change_password}>
                    <p className={styles.change_password__text}>
                        Изменить пароль
                    </p>
                    <AuthChangePassword />
                </div>
            </div>
        </div>
    );
};

export default Profile;
