import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useHorizontalScroll} from '../../static/js/scrollutils';
import {check_is_auth} from '../auth/common';
import styles from './landing.module.css';
import AuthSignUp from './../auth/auth-sign-up';
import AuthSignIn from './../auth/auth-sign-in';
import img_background_1 from './static/images/background-1.jpg';
import img_background_2 from './static/images/background-2.jpg';
import img_logo from './../../static/images/logo.svg';
import img_exchanges from './static/images/exchanges.svg';
import img_iphone_background from './static/images/iphone-background.svg';
import img_iphone from './static/images/iphone.png';
import img_laptop from './static/images/laptop.png';
import img_our_partners from './static/images/our-partners.svg';
import img_func_pic_1 from './static/images/func-pic-1.svg';
import img_func_pic_2 from './static/images/func-pic-2.svg';
import img_func_pic_3 from './static/images/func-pic-3.svg';
import img_func_pic_4 from './static/images/func-pic-4.svg';
import img_check_mark from './static/images/check-mark.svg';
import img_uncheck_mark from './static/images/uncheck-mark.svg';
import img_badge from './static/images/badge.svg';
import img_meo_backlight from './static/images/meo-backlight.png';
import img_meo from './static/images/meo.png';


const Landing = () => {
    const navigate = useNavigate();
    const review_items_scroll_ref = useHorizontalScroll();
    const [is_show_auth_sing_up, show_auth_sign_up] = useState(false);
    const [is_show_auth_sing_in, show_auth_sign_in] = useState(false);

    useEffect(() => {
        const on_click_event = (event) => {
            const question = event.target.closest(`[class*="${styles.question}"`)
            if (!question)
                return;
            const drop_down = question.querySelector(`[class*="${styles.question__drop_down}"`);
            if (!drop_down || drop_down.children.length < 1)
                return;
            const drop_down_img = drop_down.children.item(0);
            const question_block_answer = question.querySelector(`[class*="${styles.question_block__answer}"`);
            if (!question_block_answer)
                return;
            if (question.className.includes(styles.question__expanded)) {
                question.className = styles.question;
                drop_down.className = styles.question__drop_down;
                drop_down_img.className = styles.question__drop_down_img__arrow_down;
                question_block_answer.className = styles.question_block__answer + ' ' + styles.question_block__answer__hidden;
            } else {
                question.className = styles.question + ' ' + styles.question__expanded;
                drop_down.className = styles.question__drop_down + ' ' + styles.question__drop_down__expanded;
                drop_down_img.className = styles.question__drop_down_img__collapse;
                question_block_answer.className = styles.question_block__answer;
            }
        }
        const question_items = document.querySelectorAll(`[class*="${styles.question__drop_down}"`);
        for (const item of question_items)
            item.addEventListener('click', on_click_event);

        const resize_observer = new ResizeObserver((entries) => {
            const background_2 = document.querySelector(`[class*="${styles.page__background_image_2}"`);
            if (!background_2)
                return;
            for(const entry of entries) {
                background_2.style.top = `${entry.contentRect.height - 680}px`;
            }
        });
        const resize_items = document.querySelector(`[class*="${styles.questions_list}"`);
        resize_observer.observe(resize_items);

        return () => {
            resize_observer.unobserve(resize_items);
            for (const item of question_items)
                item.removeEventListener('click', on_click_event);
        };
    }, []);

    const on_sign_up_clicked = async (event) => {
        if (await check_is_auth())
            return navigate('/account');
        show_auth_sign_up(true);
        show_auth_sign_in(false);
    };

    const on_sign_in_clicked = async (event) => {
        if (await check_is_auth())
            return navigate('/account');
        show_auth_sign_up(false);
        show_auth_sign_in(true);
    };

    return (
        <div className={styles.page}>
            {is_show_auth_sing_up ? <AuthSignUp show_auth_sign_up={show_auth_sign_up} show_auth_sign_in={show_auth_sign_in} /> : <template/>}
            {is_show_auth_sing_in ? <AuthSignIn show_auth_sign_up={show_auth_sign_up} show_auth_sign_in={show_auth_sign_in} /> : <template/>}
            <div className={styles.page_background}>
                <img className={styles.page__background_image_1} src={img_background_1} alt="Background"/>
                <img className={styles.page__background_image_2} src={img_background_2} alt="Background"/>
            </div>
            <div className={styles.content_background}>
                <div className={styles.content}>
                    <header className={styles.header}>
                        <div className={styles.logo}>
                            <img className={styles.logo__icon} src={img_logo} alt="Logo"/>
                            <p className={styles.logo__text}>Trader<br/>diary</p>
                        </div>
                        <nav>
                            <ul className={styles.navigation}>
                                <li className={styles.navigation__item+' '+styles.navigation__link}>Аналитика</li>
                                <li className={styles.navigation__item+' '+styles.navigation__link}>Тарифы</li>
                                <li className={styles.navigation__item+' '+styles.navigation__link}>FAQ</li>
                                <li className={styles.navigation__item+' '+styles.navigation__link}>Возможности</li>
                                <li className={styles.navigation__item+' '+styles.navigation__link} onClick={on_sign_in_clicked}>Войти</li>
                            </ul>
                        </nav>
                    </header>
                    <main className={styles.main}>
                        <div className={styles.section_1}>
                            <section className={styles.section_1_info}>
                                <div className={styles.section_1_logo}>
                                    <div className={styles.section_1_logo__backlight}></div>
                                    <img className={styles.section_1_logo__icon} src={img_logo} alt="Logo"/>
                                    <p className={styles.section_1_logo__text}>Trader<br/>diary</p>
                                </div>
                                <p className={styles.section_1__text}>
                                    Автоматизированный Журнал Сделок<br/>Трейдера Криптовалют от Евгения Михеева
                                </p>
                                <div className={styles.section_1__register_button_block}>
                                    <div className={styles.section_1__register_button_backlight}></div>
                                    <input className={styles.section_1__register_button} type="button" onClick={on_sign_up_clicked}
                                           value="Зарегистрироваться сейчас"/>
                                </div>
                                <img className={styles.section_1__exchanges} src={img_exchanges} alt="Exchanges"/>
                            </section>
                            <div className={styles.iphone}>
                                <img className={styles.iphone__background} src={img_iphone_background} alt="Phone background"/>
                                <div className={styles.iphone__backlight}></div>
                                <img className={styles.iphone__screen} src={img_iphone} alt="Phone"/>
                            </div>
                        </div>
                        <div className={styles.section_2}>
                            <div className={styles.section_2__laptop_backlight}></div>
                            <div className={styles.section_2__laptop}>
                                <img className={styles.section_2__laptop_imp} src={img_laptop} alt="Laptop"/>
                            </div>
                            <section className={styles.section_2__stats}>
                                <p className={styles.section_2__text_1}>Что такое</p>
                                <p className={styles.section_2__text_2}>Trader Diary?</p>
                                <p className={styles.section_2__text_3}>
                                    Trader Diary - это ваш дневник трейдера,<br/>
                                    с помощью которого вы сможете эффективно
                                    <br/>отслеживать вашу торговую историю
                                </p>
                                <div className={styles.section_2__grid}>
                                    <section className={styles.section_2__trades_count}>
                                        <p className={styles.section_2__stats_number_text}>1.000.000</p>
                                        <p className={styles.section_2__stats_info_text}>Сделок совершено</p>
                                    </section>
                                    <section className={styles.section_2__users_count}>
                                        <p className={styles.section_2__stats_number_text}>20.000</p>
                                        <p className={styles.section_2__stats_info_text}>Используют Trader Diary</p>
                                    </section>
                                </div>
                            </section>
                        </div>
                        <div className={styles.section_3}>
                            <div className={styles.section_3__screenshots}></div>
                        </div>
                        <div className={styles.section_4}>
                            <div className={styles.section_4_block}>
                                <section>
                                    <div className={styles.section_4__light_dot}></div>
                                    <p className={styles.section_4__text_1}>Автообновление<br/>сделок</p>
                                    <p className={styles.section_4__text_2}>Сделки обновляются автоматически,<br/>предоставляя актуальную информацию<br/>
                                        по рынку</p>
                                </section>
                            </div>
                            <div className={styles.section_4_block}>
                                <section>
                                    <div className={styles.section_4__light_dot}></div>
                                    <p className={styles.section_4__text_1}>Все аккаунты<br/>- тут</p>
                                    <p className={styles.section_4__text_2}>Не нужно переключаться между<br/>платформами все в один клик</p>
                                </section>
                            </div>
                            <div className={styles.section_4_block}>
                                <section>
                                    <div className={styles.section_4__light_dot}></div>
                                    <p className={styles.section_4__text_1}>История<br/>сделок</p>
                                    <p className={styles.section_4__text_2}>Доступ ко всем вашим сделкам<br/>с каждым шагом на графике</p>
                                </section>
                            </div>
                        </div>
                        <div className={styles.platform_review}>
                            <p className={styles.platform_review__title}>Что говорят пользователи</p>
                            <div className={styles.review_items} ref={review_items_scroll_ref}>
                                <div className={styles.review_space_item}></div>
                                <div className={styles.review_item}>
                                    <p className={styles.review_item__user_name}>Евгений Михеев</p>
                                    <p className={styles.review_item__user_nickname}>@niknametg</p>
                                    <p className={styles.review_item__user_review_text}>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.</p>
                                </div>
                                <div className={styles.review_item}>
                                    <p className={styles.review_item__user_name}>Евгений Михеев</p>
                                    <p className={styles.review_item__user_nickname}>@niknametg</p>
                                    <p className={styles.review_item__user_review_text}>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.</p>
                                </div>
                                <div className={styles.review_item}>
                                    <p className={styles.review_item__user_name}>Евгений Михеев</p>
                                    <p className={styles.review_item__user_nickname}>@niknametg</p>
                                    <p className={styles.review_item__user_review_text}>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.</p>
                                </div>
                                <div className={styles.review_item}>
                                    <p className={styles.review_item__user_name}>Евгений Михеев</p>
                                    <p className={styles.review_item__user_nickname}>@niknametg</p>
                                    <p className={styles.review_item__user_review_text}>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.</p>
                                </div>
                                <div className={styles.review_item}>
                                    <p className={styles.review_item__user_name}>Евгений Михеев</p>
                                    <p className={styles.review_item__user_nickname}>@niknametg</p>
                                    <p className={styles.review_item__user_review_text}>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.</p>
                                </div>
                                <div className={styles.review_item}>
                                    <p className={styles.review_item__user_name}>Евгений Михеев</p>
                                    <p className={styles.review_item__user_nickname}>@niknametg</p>
                                    <p className={styles.review_item__user_review_text}>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.</p>
                                </div>
                                <div className={styles.review_item}>
                                    <p className={styles.review_item__user_name}>Евгений Михеев</p>
                                    <p className={styles.review_item__user_nickname}>@niknametg</p>
                                    <p className={styles.review_item__user_review_text}>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>
                                        Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.<br/>Тут текст отзыва о платформе.</p>
                                </div>
                                <div className={styles.review_space_item}></div>
                            </div>
                        </div>
                        <div className={styles.our_partners}>
                            <p className={styles.our_partners__title}>
                                Наши <span className={styles.our_partners__title+' '+styles.our_partners__title__illuminated}>партнеры</span>
                            </p>
                            <div className={styles.our_partners__backlight}></div>
                            <img className={styles.our_partners__logo} src={img_our_partners} alt="Our partners"/>
                        </div>
                        <div className={styles.functional}>
                            <p className={styles.functional__title}>
                                Что может<br/>
                                <span className={styles.functional__title+' '+styles.functional__title__illuminated}>
                                    Trader Diary?
                                </span>
                            </p>
                            <div className={styles.functional_items}>
                                <div className={styles.functional_item}>
                                    <img className={styles.functional_item__image} src={img_func_pic_4} alt="Function"/>
                                    <p className={styles.functional_item__text_1}>Добавляйте причины входа,<br/>выводы и описания к сделкам.</p>
                                    <p className={styles.functional_item__text_2}>Прибыль и все скрытые комиссии мы<br/>заполним автоматически через API</p>
                                </div>
                                <div className={styles.functional_item}>
                                    <img className={styles.functional_item__image} src={img_func_pic_3} alt="Function"/>
                                    <p className={styles.functional_item__text_1}>Доступ к данным<br/>на графике с каждым<br/>шагом в сделке</p>
                                </div>
                                <div className={styles.functional_item}>
                                    <img className={styles.functional_item__image} src={img_func_pic_2} alt="Function"/>
                                    <p className={styles.functional_item__text_1}>Удобные виджеты метрик<br/>для быстрого<br/>и подробного анализа</p>
                                </div>
                                <div className={styles.functional_item}>
                                    <img className={styles.functional_item__image} src={img_func_pic_1} alt="Function"/>
                                    <p className={styles.functional_item__text_1}>Полностью настраиваемая<br/>панель аналитики:</p>
                                    <p className={styles.functional_item__text_2}>настройте виджеты которые нужны вам,<br/>ненужные - скройте</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.questions}>
                            <p className={styles.questions__title}>Ответы на часто<br/>задаваемые вопросы</p>
                            <div className={styles.questions_list}>
                                <div className={styles.question}>
                                    <div className={styles.question_block}>
                                        <p className={styles.question_block__text}>
                                            1. Какие вопросы часто задают люди, из целевой аудитории?
                                        </p>
                                        <p className={styles.question_block__answer+' '+styles.question_block__answer__hidden}>
                                            Опишите ответ на вопрос, который написан выше. Чем подробнее будет ответ, тем лучше.
                                            Собирайте вопросы и добавляйте вопросы, которые поступают от клиентов и пишите ответы.
                                            Это пример ответа на вопрос. Вам необходимо отредактировать этот текст,
                                            поскольку сейчас он не несет в себе никакого смысла. Здесь представлен просто текст.
                                            Скорее редактируйте его.
                                        </p>
                                    </div>
                                    <div className={styles.question__drop_down}>
                                        <img className={styles.question__drop_down_img__arrow_down} alt="Arrow down"/>
                                    </div>
                                </div>
                                <div className={styles.question}>
                                    <div className={styles.question_block}>
                                        <p className={styles.question_block__text}>
                                            2. Какие сомнения, страхи, опасения, есть у потенциальных клиентов?
                                        </p>
                                        <p className={styles.question_block__answer+' '+styles.question_block__answer__hidden}>
                                            Опишите ответ на вопрос, который написан выше. Чем подробнее будет ответ, тем лучше.
                                            Собирайте вопросы и добавляйте вопросы, которые поступают от клиентов и пишите ответы.
                                            Это пример ответа на вопрос. Вам необходимо отредактировать этот текст,
                                            поскольку сейчас он не несет в себе никакого смысла. Здесь представлен просто текст.
                                            Скорее редактируйте его.
                                        </p>
                                    </div>
                                    <div className={styles.question__drop_down}>
                                        <img className={styles.question__drop_down_img__arrow_down} alt="Arrow down"/>
                                    </div>
                                </div>
                                <div className={styles.question}>
                                    <div className={styles.question_block}>
                                        <p className={styles.question_block__text}>
                                            3. Подумайте и напишите, что важно для людей из целевой аудитории?
                                        </p>
                                        <p className={styles.question_block__answer+' '+styles.question_block__answer__hidden}>
                                            Опишите ответ на вопрос, который написан выше. Чем подробнее будет ответ, тем лучше.
                                            Собирайте вопросы и добавляйте вопросы, которые поступают от клиентов и пишите ответы.
                                            Это пример ответа на вопрос. Вам необходимо отредактировать этот текст,
                                            поскольку сейчас он не несет в себе никакого смысла. Здесь представлен просто текст.
                                            Скорее редактируйте его.
                                        </p>
                                    </div>
                                    <div className={styles.question__drop_down}>
                                        <img className={styles.question__drop_down_img__arrow_down} alt="Arrow down"/>
                                    </div>
                                </div>
                                <div className={styles.question}>
                                    <div className={styles.question_block}>
                                        <p className={styles.question_block__text}>
                                            4. Пример ответа на вопрос в развернутом виде. Отредактируйте текст?
                                        </p>
                                        <p className={styles.question_block__answer+' '+styles.question_block__answer__hidden}>
                                            Опишите ответ на вопрос, который написан выше. Чем подробнее будет ответ, тем лучше.
                                            Собирайте вопросы и добавляйте вопросы, которые поступают от клиентов и пишите ответы.
                                            Это пример ответа на вопрос. Вам необходимо отредактировать этот текст,
                                            поскольку сейчас он не несет в себе никакого смысла. Здесь представлен просто текст.
                                            Скорее редактируйте его.
                                        </p>
                                    </div>
                                    <div className={styles.question__drop_down}>
                                        <img className={styles.question__drop_down_img__arrow_down} alt="Arrow down"/>
                                    </div>
                                </div>
                                <div className={styles.question}>
                                    <div className={styles.question_block}>
                                        <p className={styles.question_block__text}>
                                            5. Какие вопросы часто задают люди, из целевой аудитории?
                                        </p>
                                        <p className={styles.question_block__answer+' '+styles.question_block__answer__hidden}>
                                            Опишите ответ на вопрос, который написан выше. Чем подробнее будет ответ, тем лучше.
                                            Собирайте вопросы и добавляйте вопросы, которые поступают от клиентов и пишите ответы.
                                            Это пример ответа на вопрос. Вам необходимо отредактировать этот текст,
                                            поскольку сейчас он не несет в себе никакого смысла. Здесь представлен просто текст.
                                            Скорее редактируйте его.
                                        </p>
                                    </div>
                                    <div className={styles.question__drop_down}>
                                        <img className={styles.question__drop_down_img__arrow_down} alt="Arrow down"/>
                                    </div>
                                </div>
                                <div className={styles.question}>
                                    <div className={styles.question_block}>
                                        <p className={styles.question_block__text}>
                                            6. Какие сомнения, страхи, опасения, есть у потенциальных клиентов?
                                        </p>
                                        <p className={styles.question_block__answer+' '+styles.question_block__answer__hidden}>
                                            Опишите ответ на вопрос, который написан выше. Чем подробнее будет ответ, тем лучше.
                                            Собирайте вопросы и добавляйте вопросы, которые поступают от клиентов и пишите ответы.
                                            Это пример ответа на вопрос. Вам необходимо отредактировать этот текст,
                                            поскольку сейчас он не несет в себе никакого смысла. Здесь представлен просто текст.
                                            Скорее редактируйте его.
                                        </p>
                                    </div>
                                    <div className={styles.question__drop_down}>
                                        <img className={styles.question__drop_down_img__arrow_down} alt="Arrow down"/>
                                    </div>
                                </div>
                                <div className={styles.question}>
                                    <div className={styles.question_block}>
                                        <p className={styles.question_block__text}>
                                            7. Подумайте и напишите, что важно для людей из целевой аудитории?
                                        </p>
                                        <p className={styles.question_block__answer+' '+styles.question_block__answer__hidden}>
                                            Опишите ответ на вопрос, который написан выше. Чем подробнее будет ответ, тем лучше.
                                            Собирайте вопросы и добавляйте вопросы, которые поступают от клиентов и пишите ответы.
                                            Это пример ответа на вопрос. Вам необходимо отредактировать этот текст,
                                            поскольку сейчас он не несет в себе никакого смысла. Здесь представлен просто текст.
                                            Скорее редактируйте его.
                                        </p>
                                    </div>
                                    <div className={styles.question__drop_down}>
                                        <img className={styles.question__drop_down_img__arrow_down} alt="Arrow down"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.section_5}>
                            <p className={styles.section_5__title}>
                                Анализировать<br/>сделки <span className="section-5__title--highlighted">- просто</span>
                            </p>
                            <div className={styles.section_5_grid}>
                                <div className={styles.section_5_gif}></div>
                                <section className={styles.section_5_texts}>
                                    <div className={styles.section_5_text}>
                                        <p className={styles.section_5_text__number}>01</p>
                                        <p className={styles.section_5_text__text}>Отслеживайте<br/>свою прибыль</p>
                                    </div>
                                    <div className={styles.section_5_text}>
                                        <p className={styles.section_5_text__number}>03</p>
                                        <p className={styles.section_5_text__text}>Узнайте прибыльное<br/>и убыточное время</p>
                                    </div>
                                    <div className={styles.section_5_text}>
                                        <p className={styles.section_5_text__number}>02</p>
                                        <p className={styles.section_5_text__text}>Найдите<br/>убыточные пары</p>
                                    </div>
                                    <div className={styles.section_5_text}>
                                        <p className={styles.section_5_text__number}>04</p>
                                        <p className={styles.section_5_text__text}>Поймите, какие сделки<br/>не приносят прибыли</p>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className={styles.tariffs}>
                            <p className={styles.tariffs__title}>Тарифы</p>
                            <div className={styles.tariff_list}>
                                <div className={styles.tariff_list__backlight}></div>
                                <div className={styles.tariff}>
                                    <p className={styles.tariff__title}>Старт</p>
                                    <hr className={styles.tariff_hr}/>
                                    <div className={styles.tariff__option}>
                                        <img className={styles.tariff__option_icon} src={img_check_mark} alt="Сheck mark"/>
                                        <p className={styles.tariff__option_text}>Выгода, функция, бонус</p>
                                    </div>
                                    <hr className={styles.tariff_hr}/>
                                    <div className={styles.tariff__option}>
                                        <img className={styles.tariff__option_icon} src={img_check_mark} alt="Сheck mark"/>
                                        <p className={styles.tariff__option_text}>Выгода, функция, бонус</p>
                                    </div>
                                    <hr className={styles.tariff_hr}/>
                                    <div className={styles.tariff__option}>
                                        <img className={styles.tariff__option_icon} src={img_uncheck_mark} alt="Сheck mark"/>
                                        <p className={styles.tariff__option_text+' '+styles.tariff__option_text__unchecked}>Выгода, функция, бонус</p>
                                    </div>
                                    <hr className={styles.tariff_hr}/>
                                    <div className={styles.tariff__option}>
                                        <img className={styles.tariff__option_icon} src={img_uncheck_mark} alt="Сheck mark"/>
                                        <p className={styles.tariff__option_text+' '+styles.tariff__option_text__unchecked}>Выгода, функция, бонус</p>
                                    </div>
                                    <hr className={styles.tariff_hr}/>
                                    <p className={styles.tariff__text_1}>В месяц</p>
                                    <p className={styles.tariff__text_2}>Бесплатно</p>
                                    <div className={styles.tariff_button_block}>
                                        <div className={styles.tariff__button_backlight}></div>
                                        <input className={styles.tariff__button} type="button" value="Выбрать"/>
                                    </div>
                                </div>
                                <div className={styles.tariff}>
                                    <p className={styles.tariff__title+' '+styles.tariff__title__illuminated}>Бизнес</p>
                                    <hr className={styles.tariff_hr}/>
                                    <div className={styles.tariff__option}>
                                        <img className={styles.tariff__option_icon} src={img_check_mark} alt="Сheck mark"/>
                                        <p className={styles.tariff__option_text}>Выгода, функция, бонус</p>
                                    </div>
                                    <hr className={styles.tariff_hr}/>
                                    <div className={styles.tariff__option}>
                                        <img className={styles.tariff__option_icon} src={img_check_mark} alt="Сheck mark"/>
                                        <p className={styles.tariff__option_text}>Выгода, функция, бонус</p>
                                    </div>
                                    <hr className={styles.tariff_hr}/>
                                    <div className={styles.tariff__option}>
                                        <img className={styles.tariff__option_icon} src={img_check_mark} alt="Сheck mark"/>
                                        <p className={styles.tariff__option_text}>Выгода, функция, бонус</p>
                                    </div>
                                    <hr className={styles.tariff_hr}/>
                                    <div className={styles.tariff__option}>
                                        <img className={styles.tariff__option_icon} src={img_check_mark} alt="Сheck mark"/>
                                        <p className={styles.tariff__option_text}>Выгода, функция, бонус</p>
                                    </div>
                                    <hr className={styles.tariff_hr}/>
                                    <div className={styles.tariff__option}>
                                        <img className={styles.tariff__option_icon} src={img_check_mark} alt="Сheck mark"/>
                                        <p className={styles.tariff__option_text}>Выгода, функция, бонус</p>
                                    </div>
                                    <hr className={styles.tariff_hr}/>
                                    <div className={styles.tariff__option}>
                                        <img className={styles.tariff__option_icon} src={img_check_mark} alt="Сheck mark"/>
                                        <p className={styles.tariff__option_text}>Выгода, функция, бонус</p>
                                    </div>
                                    <hr className={styles.tariff_hr}/>
                                    <p className={styles.tariff__text_1}>В месяц</p>
                                    <p className={styles.tariff__text_2}>10900 ₽</p>
                                    <div className={styles.tariff_button_block}>
                                        <div className={styles.tariff__button_backlight+' '+styles.tariff__button_backlight__illuminated}></div>
                                        <input className={styles.tariff__button+' '+styles.tariff__button__illuminated} type="button"
                                               value="Выбрать"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.creator}>
                            <p className={styles.creator__text_1}>Создатель платформы</p>
                            <p className={styles.creator__text_2}>Евгений Михееев</p>
                            <div className={styles.creator_grid}>
                                <div className={styles.creator_info_grid}>
                                    <div className={styles.creator_info+' '+styles.creator_info__shift_1}>
                                        <img className={styles.creator_info__icon} src={img_badge} alt="Icon"/>
                                        <p className={styles.creator_info__text}>Автор “Метод Михеева 2024”</p>
                                    </div>
                                    <div className={styles.creator_info+' '+styles.creator_info__shift_2}>
                                        <img className={styles.creator_info__icon} src={img_badge} alt="Icon"/>
                                        <p className={styles.creator_info__text}>
                                            В 2018 году занял 44 место среди<br/>27 тыс. инвесторов из России и СНГ<br/>
                                            в конкурсе ЛЧИ от Московской биржи
                                        </p>
                                    </div>
                                    <div className={styles.creator_info+' '+styles.creator_info__shift_3}>
                                        <img className={styles.creator_info__icon} src={img_badge} alt="Icon"/>
                                        <p className={styles.creator_info__text}>За последний год<br/>заработал + 180% к депозиту ($242 000)</p>
                                    </div>
                                    <div className={styles.creator_info_button_block+' '+styles.creator_info__shift_4}>
                                        <div className={styles.creator__info_button_backlight}></div>
                                        <input className={styles.creator__info_button} type="button" value="Подробнее о методе"/>
                                    </div>
                                </div>
                                <div className={styles.creator_quotes}>
                                    <img className={styles.creator_quotes__background_backlight} src={img_meo_backlight} alt="Icon"/>
                                    <img className={styles.creator_quotes__background} src={img_meo} alt="Icon"/>
                                    <div className={styles.creator_quotes__backlight}></div>
                                    <div className={styles.creator_quote}>
                                        <p className={styles.creator_quote__double_quotes+' '+styles.creator_quote__double_quotes__top_left}>“</p>
                                        <p className={styles.creator_quote__text}>
                                            Я сам практикующий трейдер и знаю, что нужно<br/>
                                            пользователям и моим ученикам для эффективной<br/>торговли
                                        </p>
                                        <p className={styles.creator_quote__double_quotes+' '+styles.creator_quote__double_quotes__bottom_right}>“</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <footer className={styles.footer}>
                        <div className={styles.footer_grid}>
                            <div className={styles.menu_main}>
                                <p className={styles.menu__title}>Главная</p>
                                <ul className={styles.menu__list}>
                                    <li className={styles.menu__item+' '+styles.menu__item_link}>
                                        Что такое Trader Diary?
                                    </li>
                                    <li className={styles.menu__item+' '+styles.menu__item_link}>
                                        Что говорят пользователи
                                    </li>
                                    <li className={styles.menu__item+' '+styles.menu__item_link}>
                                        Наши партнеры
                                    </li>
                                    <li className={styles.menu__item+' '+styles.menu__item_link}>
                                        Что может Trader Diary
                                    </li>
                                    <li className={styles.menu__item+' '+styles.menu__item_link}>
                                        FAQ
                                    </li>
                                    <li className={styles.menu__item+' '+styles.menu__item_link}>
                                        Тарифы
                                    </li>
                                    <li className={styles.menu__item+' '+styles.menu__item_link}>
                                        Метод 2024
                                    </li>
                                    <li className={styles.menu__item}>
                                        <span className={styles.menu__item_link} onClick={on_sign_in_clicked}>
                                            Войти
                                        </span>
                                        <span className={styles.menu__item_link+' '+styles.menu__item_link__shifted} onClick={on_sign_up_clicked}>
                                            Зарегистрироваться
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.menu_docs}>
                                <p className={styles.menu__title}>Документация</p>
                                <ul className={styles.menu__list}>
                                    <li className={styles.menu__item+' '+styles.menu__item_link}>
                                        Политика конфиденциальности
                                    </li>
                                    <li className={styles.menu__item+' '+styles.menu__item_link}>
                                        Пользовательское соглашение
                                    </li>
                                    <li className={styles.menu__item+' '+styles.menu__item_link}>
                                        Политика возврата
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.menu_contacts}>
                                <p className={styles.menu__title}>Контакты</p>
                                <p className={styles.menu_contacts__number}>+7 (000) 000‑00-00</p>
                                <p className={styles.menu_contacts__number_description}>Горячая линия</p>
                                <ul className={styles.menu__list}>
                                    <li className={styles.menu__item+' '+styles.menu__item_link+' '+styles.menu__item_link__underline}>
                                        Поддержка в Telegram
                                    </li>
                                    <li className={styles.menu__item+' '+styles.menu__item_link+' '+styles.menu__item_link__underline}>
                                        Наш Telegram канал
                                    </li>
                                    <li className={styles.menu__item+' '+styles.menu__item_link+' '+styles.menu__item_link__underline}>
                                        info@traderdiary.com
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.company_info}>
                                <p className={styles.menu__title+' '+styles.menu__title__trader_diary}>Trader Diary</p>
                                <ul className={styles.menu__list}>
                                    <li className={styles.menu__item+' '+styles.company_info__text}>
                                        © nazvaniesaita.ru, 2034
                                    </li>
                                    <li className={styles.menu__item+' '+styles.company_info__text}>
                                        ОГРН 0000000000000
                                    </li>
                                    <li className={styles.menu__item+' '+styles.company_info__text}>
                                        г. Москва, ул. Ленина 345, офис 34
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.footer_disclaimer}>
                            <p className={styles.footer_disclaimer__text}>Информация, предоставленная на этом веб-сайте, предназначена только для
                                информационных и образовательных целей. Веб-сайт не<br/>держит или не оперирует активами пользователей и не
                                предоставляет инвестиционные, финансовые, правовые, налоговые или другие<br/>советы. Любой анализ или визуальное
                                представление финансовых данных предназначено для общей информации и не должно служить<br/>основанием для принятия
                                инвестиционных решений. Пользователи всегда должны проводить свои собственные исследования и<br/>
                                принимать собственные решения.</p>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default Landing;
