import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styles from './apikeydialog.module.css';
import axios from "axios";
import {get_axios_config} from "../../../../static/js/network";
import {parse_html_with_css} from "../../../../static/js/parsers";
import DOMPurify from "dompurify";
import $ from "jquery";
import img_close from '../../../../static/images/close.svg';
import img_warning from '../../../../static/images/warning-triangle.svg';
import {clear_all_messages} from "../../../auth/common";


const ApiKeyDialog = (props) => {
    const [form_html, set_form_html] = useState(null);
    const key_data = useRef(null);

    useLayoutEffect(() => {
        setup_new_api_key();
    }, []);

    useEffect(() => {
        window.addEventListener('click', on_window_click);
        return () => {
            window.removeEventListener('click', on_window_click);
        };
    }, []);

    useEffect(() => {
        if (form_html) {
            if (props.edit_mode)
                setup_edit_api_key();
            const form = document.querySelector('#new_api_key_form');
            if (form) {
                form.addEventListener('submit', new_api_key_form_submit_handler);
                const cancel = form.querySelector(`[class*="${styles.button__cancel}"]`);
                if (cancel)
                    cancel.addEventListener('click', on_cancel_click);
            }
        }
        return () => {
            if (form_html) {
                const form = document.querySelector('#new_api_key_form');
                if (form) {
                    form.removeEventListener('submit', new_api_key_form_submit_handler);
                    const cancel = form.querySelector(`[class*="${styles.button__cancel}"]`);
                    if (cancel)
                        cancel.removeEventListener('click', on_cancel_click);
                }
            }
        };
    }, [form_html]);

    const setup_new_api_key = async () => {
        if (form_html)
            return;
        axios.get('/api/exchanges/getnewapikeyform/', get_axios_config('get', 'html'))
            .then((response) => {
                if (response.status === 200) {
                    if (!form_html)
                        set_form_html(parse_html_with_css(DOMPurify.sanitize(response.data), styles));
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const setup_edit_api_key = async () => {
        axios.get(`/api/exchanges/getapikey/${props.key_id}/`, get_axios_config('get', 'json'))
            .then((response) => {
                if (response.status === 200) {
                    key_data.current = response.data.key;
                    const form = document.querySelector('#new_api_key_form');
                    if (!form || !key_data.current)
                        return;
                    const account_name = form.querySelector('#id_account_name');
                    if (account_name)
                        account_name.value = key_data.current.account_name;
                    const public_api_key = form.querySelector('#id_public_api_key');
                    if (public_api_key)
                        public_api_key.value = key_data.current.public_api_key;
                    const secret_api_key = form.querySelector('#id_secret_api_key');
                    if (secret_api_key)
                        secret_api_key.value = key_data.current.secret_api_key;
                }
            })
            .catch((error) => {
                console.error(error);
            })
    };

    const new_api_key_form_submit_handler = (event) => {
        event.preventDefault();
        clear_all_messages();
        if (props.edit_mode && !key_data.current)
            return;
        const data = `${$('#new_api_key_form').serialize()}&exchange_name=${
                props.edit_mode ? 
                key_data.current.exchange_name : 
                props.exchange_name
            }&category=${
                props.edit_mode ? 
                key_data.current.category : 
                props.category
            }`;
        const url = `/api/exchanges/${props.edit_mode ? `updateapikey/${key_data.current.id}` : 'addapikey'}/`;
        axios.post(url, data, get_axios_config('post', 'form'))
            .then((response) => {
                if (response.status === 200 || response.status === 201)
                    return props.set_show_api_key_dialog(false);
            })
            .catch((error) => {
                if (error.status === 400)
                    return set_error_message(error.response.data.code, error.response.data.msg);
                console.error(error);
            });
    }

    const clear_all_messages = () => {
        const items = document.querySelectorAll(`[class*="${styles.field__error}"`);
        for(const item of items) {
            item.innerHTML = '';
            if (!item.classList.contains(styles.field__error__hidden))
                item.classList.add(styles.field__error__hidden);
        }
    };

    const set_error_message = (code, msg) => {
        var error_text = null;
        switch(code) {
            case 0:
                error_text = document.querySelector('#new_api_key_form')
                                     .querySelector(`[class*="${styles.field__form}"]`);
                break;
            case 1:
                error_text = document.querySelector('#new_api_key_form')
                                     .querySelector(`[class*="${styles.field__account_name}"]`);
                break;
            case 2:
                error_text = document.querySelector('#new_api_key_form')
                                     .querySelector(`[class*="${styles.field__public_api_key}"]`);
                break;
            case 3:
                error_text = document.querySelector('#new_api_key_form')
                                     .querySelector(`[class*="${styles.field__secret_api_key}"]`);
                break;
            case 4:
                error_text = document.querySelector('#new_api_key_form')
                                     .querySelector(`[class*="${styles.field__passphrase}"]`);
                break;
            default:
                break;
        }
        if (!error_text)
            return;
        error_text.innerHTML = DOMPurify.sanitize(msg);
        if (error_text.classList.contains(styles.field__error__hidden))
            error_text.classList.remove(styles.field__error__hidden);
    };

    const on_window_click = (event) => {
        if (!(event.target.closest(`[class*=${styles.api_key_dialog}]`)) && !check_fields())
            props.set_show_api_key_dialog(false);
    };

    const on_cancel_click = (event) => {
        event.stopPropagation();
        props.set_show_api_key_dialog(false);
    };

    const get_info_block = () => {
        return (
            <div className={styles.info_block}>
                <p className={styles.info__text}>
                    Как это работает:
                </p>
                <ul className={styles.info_list}>
                    <li className={styles.info__text}>
                        Будет загружена история последних 2 лет на бирже.
                    </li>
                    <li className={styles.info__text}>
                        Будут загружены только линейные бессрочные фьючерсы (/USDT).
                    </li>
                </ul>
                <p className={styles.info__text}><br/>
                    Как создать api ключ:
                </p>
                <ul className={styles.info_list}>
                    <li className={styles.info__text}>
                        Перейдите в&nbsp;
                        <a target="_blank" href="https://www.bybit.com/app/user/api-management">
                            https://www.bybit.com/app/user/api-management
                        </a>
                    </li>
                    <li className={styles.info__text}>
                        Нажмите «Создать новый ключ», выберите «API ключи, созданные системой».
                    </li>
                    <li className={styles.info__text}>
                        В разделе «Использование ключа API» выберите «Привязка к стороннему приложению».
                    </li>
                    <li className={styles.info__text}>
                        По умолчанию параметр «Разрешения API ключа» доступен только для чтения.<br/>Нет необходимости изменять его.
                    </li>
                    <li className={styles.info__text}>
                        Включите все доступные флажки и нажмите «Отправить».
                    </li>
                    <li className={styles.info__text}>
                        Скопируйте API ключ, API секрет и вставьте их в форму слева.
                    </li>
                </ul>
            </div>
        );
    };

    const check_fields = () => {
        const form = document.querySelector('#new_api_key_form');
        if (!form)
            return false;
        const account_name = form.querySelector('#id_account_name');
        const public_api_key = form.querySelector('#id_public_api_key');
        const secret_api_key = form.querySelector('#id_secret_api_key');
        return ((account_name && account_name.value) ||
                (public_api_key && public_api_key.value) ||
                (secret_api_key && secret_api_key.value));
    }

    return (
        <div className={styles.block}>
            <div className={styles.api_key_dialog}>
                <div className={styles.api_key_form}>
                    <div className={styles.read_only_alarm}>
                        <img className={styles.read_only_alarm__img} src={img_warning} alt="Warning!"/>
                        <p className={styles.read_only_alarm__text}>
                            Всегда создавайте ключ <strong>только для чтения</strong>!
                        </p>
                    </div>
                    {form_html}
                </div>
                {!props.edit_mode ? get_info_block() : <template/>}
                <div className={styles.close_block}>
                    <img className={styles.close__img} src={img_close} alt="Close"
                         onClick={(event) => on_cancel_click(event)}/>
                </div>
            </div>
        </div>
    );
};

export default ApiKeyDialog;
