import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Landing from './components/landing/landing';
import Account from "./components/account/account";


const App = () => {
  return (
      <BrowserRouter>
          <Routes>
              <Route path='/' element={<Landing />}/>
              <Route path='/account/*' element={<Account />}/>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
